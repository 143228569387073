import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import { RecoilRoot } from 'recoil';

function App() {
  return (
      <RecoilRoot>
        <Home/>
      </RecoilRoot>
    );
}

export default App;
