import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { MButton } from "../../components/Buttons/MButton";
import { navigateto } from "../../Routes";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { check_is_200, post } from "../../Helpers/xhr";
import toast from "react-hot-toast";
import Reclamation_List from "./list";

const Reclamation_List_Infra = () => {
    const { infra_key } = useParams();
    const history = useHistory();

    return <>
        <div className="header1">Les réclamations de l'infrastructure</div>
        <MButton onClick={()=>navigateto({history, to:'claim_infra_add', params:[infra_key]})} titre="Ajouter" />
        {infra_key && <Reclamation_List forInfra infraTkn={infra_key} />}
    </>
}
 
export default Reclamation_List_Infra;