import { Table } from "react-bootstrap";
import { useHistory } from "react-router";
import { MButton } from "../../components/Buttons/MButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faMessage, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getItemRoute, navigateto } from "../../Routes";
import { Col, Container, Row } from "react-bootstrap";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { check_is_200, deleta, get, post } from "../../Helpers/xhr";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { MyModal } from "../../components/modals/myModal";
import { confirmAlert } from "react-confirm-alert";
import { filter_search_check_item, sort_function_by } from "../../Helpers/DataTableOpt";
import DataTable from "react-data-table-component";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";

const Infrastructure_List = () => {

  const { userToken } = useSelector((state) => state.auth);
  const history = useHistory();
  const [infrastructures, setInfrastructures] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState('');

  //const account = useSelector((state) => state.account);
  const getData = () => {
      const toastId = toast.loading('Chargement');
        
      post('infra_list', userToken)
      .then(xhr => {
          if(check_is_200(xhr) && ('lst' in xhr.data))
          {
            setInfrastructures(xhr.data.lst);
            toast.success(<b>Terminé</b>, { id: toastId, });
          }
          else toast.error(<b>Problème de chargement</b>, { id: toastId, });
      }).catch(function (error){
          
      });
  }

  useEffect(()=>{
      getData();
  }, []);
    
    const navigate = (to) => {
        return history.push({
            pathname: getItemRoute(to),
            state:{back:true},
        });
    };

    const closeShowModal = () => setModalShow(!modalShow);
    const showMessage = (title, msg) => {
      setModalTitle(title);
      setModalContent(msg);
      closeShowModal();
    }

    const showComment = (e) => showMessage("Commentaire d'accès", e.commentaire);
    const showJustif = (e) => showMessage("Détail", e.justif);

    const deleteInfra = (e) => confirmAlert({
      title: "Suppression de l'infrastructure",
      message: 'Êtes-vous certain de cette action !',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
              
              const toastId = toast.loading('Traitement');

              post('infra_remv', userToken, {infra:e.tkn}).then(xhr => {
                  if(check_is_200(xhr) && 'state' in xhr.data)
                  {
                      if(xhr.data.state)
                      {
                          toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                          setInfrastructures(infrastructures.filter(i=>i.tkn!=e.tkn))
                      }
                      else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                  }
                  else toast.error(<b>Problème de suppression</b>, { id: toastId, });                            
              });
          }
        },
        { label: 'Non', }
      ]
    });

    const [filterKey, setFilterKey] = useState('');

    const columns = [
        { name:'Nom', sortable:true, selector:r=>r.name??''},
        { name:'Adresse', sortable:true, selector:r=>r.address??''},
        { name:'Code postal', sortable:true, selector:r=>r.cp??''},
        { name:'Ville', sortable:true, selector:r=>r.city??''},
        { name:'Télephone', sortable:true, selector:r=>r.tel??''},
        { name:'Salles', sortable:true, width:'100px', selector:r=>r.nbrSalles??''},
        { name:'Accessibilité', sortable:true, width:'120px', cell:r=><td className="tdIcons">
                                                            {r.access ? 'Oui' : 'Non'}
                                                            {r.access ? '' : <MButton className='small' iconb={<FontAwesomeIcon icon={faMessage}/>} onClick={()=>showJustif(r)} show={r.justif} />}
                                                          </td>, 
                                                          sortFunction:sort_function_by('access')},
        { name:'Actions', width:'200px', cell:e=><div className="tdIcons">
                                                    <MButton className='small' iconb={<FontAwesomeIcon icon={faInfo} />}  onClick={()=>navigateto({history, to:'infra_info', params:[e.tkn ?? '']})}  />
                                                    <MButton className='small' iconb={<FontAwesomeIcon icon={faCog}/>} onClick={()=>navigateto({history, to:'infra_update', params:[e.tkn ?? '']})}  />
                                                    <MButton className='small' iconb={<FontAwesomeIcon icon={faMessage}/>} onClick={()=>showComment(e)} show={e.commentaire} />
                                                    <MButton className="small" iconb={<FontAwesomeIcon icon={faTimes}/>} onClick={()=>deleteInfra(e)}/>
                                                  </div>
        },
    ];


    return ( <div>
      <div className="pageHeader">
        <div className="flex justify-between">
          <div className="flex flex-wrap">
            <div className="pageTitle">Infrastructure</div>
            <div style={{margin:'5px 20px'}}>
              <MButton 
                className="small"
                  titre="Ajouter" 
                  iconb={<FontAwesomeIcon icon={faPlus} />}
                  onClick={()=>navigate('infra_add')}
                />
            </div>
          </div>
          <div>
            {Attribut({ isRow:true, contentSize:9, key:"Filtrer", child:<Input_Text value={filterKey} onChange={setFilterKey} />})}
          </div>
        </div>
      </div>
        
        <DataTable
            columns={columns}
            data={infrastructures.filter(item => {
                let filter_key = filterKey.toLocaleLowerCase();
                return filter_search_check_item(filter_key, item, ['name', 'adress']);
            })}
            
            pagination
            fixedHeader
            paginationPerPage={40}
            paginationRowsPerPageOptions={[40, 60, 100]}
            noDataComponent="Aucune infrastructure !"
            paginationComponentOptions={{
                                            rowsPerPageText: 'Infrastructure par page',
                                            rangeSeparatorText: 'sur',
                                            selectAllRowsItem: true,
                                            selectAllRowsItemText: 'Tous',
                                        }}
            />
        
      <MyModal
        title={modalTitle}
        handleClose={()=>closeShowModal()}
        show={modalShow}
        content={modalContent}
        contentHtml={true}
      />
    </div> );
}
 
export default Infrastructure_List;