import { Button } from "react-bootstrap";

export function MButton({
        titre='', 
        type='button',
        iconb=null, 
        icone=null, 
        className="",
        variant="light", 
        onClick,
        show=true
}) {

    return !show ? '' : (
         onClick != null
        ? <div className={`btn btn-${variant} `+className} onClick={onClick}><div className="btn_content">{iconb} {titre} {icone}</div></div>
        : <Button type={type} variant={variant} className={"btn "+className}><div className="btn_content">{iconb} {titre} {icone}</div></Button>
    )
}