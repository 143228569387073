import { Form, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { useEffect, useState } from "react";
import { Input_Select } from "../../components/form/inputSelect";
import { MButton } from "../../components/Buttons/MButton";
import toast from "react-hot-toast";
import { check_is_200, get, post } from "../../Helpers/xhr";
import { useHistory, useParams } from "react-router-dom";
import { all_cities, all_counties } from "../../Helpers/locations";
import { Input_Check } from "../../components/form/inputCheck";
import { useSelector } from "react-redux";
import { InputInt } from "../../components/form/inputInt";

const Salle_Form = () => {

    const { userToken} = useSelector((state) => state.auth);
    const { salle_key, infra_key } = useParams();
    
    const [isUpdate, setIsUpdate] = useState(false);


    const [salle, setSalle] = useState({});
    const [Equipements, setEquipements] = useState([]);
    const [selectedEquips, setSelectedEquips] = useState([]);

    useEffect(()=>{
        loadEquipements();
        if(salle_key)
        {
            setIsUpdate(true);
            getData(salle_key);
        }
    }, [salle_key]);



    const loadEquipements = () => {
        post('salleEquips', userToken)
        .then(xhr => {
            if(check_is_200(xhr))
            {
                setEquipements(xhr.data);
            }
        }).catch(function (error){
            setEquipements([]);            
        });
    }

    const addRemiveItem = (iKey) => {
        if(selectedEquips.includes(iKey)) setSelectedEquips(selectedEquips.filter(e=>e!=iKey));
        else setSelectedEquips([...selectedEquips, iKey]);
    }

    const getData = () => {
        const toastId = toast.loading('Chargement');
          
        post('salle_info', userToken, {salle:salle_key, upd:true})
        .then(xhr => {
            console.log('salle_info', xhr.data);
            if(check_is_200(xhr) && ('salle' in xhr.data))
            {
              setSalle(xhr.data.salle);
              setSelectedEquips(xhr.data.salle.equips ?? []);
              toast.success(<b>Terminé</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            
        });
    }

    const [validated, setValidated] = useState(false);
    
    const history = useHistory();
    const goBack = () => history.goBack();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {
            let params = {...salle, eqps:selectedEquips};
            if(infra_key) params = {...params, infra:infra_key}
            else params = {...params, tkn:salle_key}
            post('salle_save', userToken, params ).then(xhr => {
                console.log('salle_save', params, xhr.data);
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goBack();
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
                    
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    return <div>
    <div className="pageHeader">
        <div className="pageTitle">{isUpdate ? 'Modification' : 'Nouvelle'} Salle</div>
    </div>
    <div>
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <Row>
                {Attribut({key:'Nom', child:<Input_Text required={true} value={salle.name} onChange={e=>setSalle({...salle, name : e})} />})}
                {Attribut({key:'SUPERFICIE (M²)', size:3, child:<Input_Text required={false} value={salle.superficie} onChange={e=>setSalle({...salle, superficie : e})} />})}
            </Row>
            <Row>
                {Attribut({key:"Description", child:<Input_Text required={false} type="text" as="textarea" value={salle.commentaire} onChange={e=>setSalle({...salle, commentaire : e})} />})}
            </Row>
            <Row>
                {Attribut({key:"Equipements", child:<div>
                    {Equipements.map((e, k)=><MButton 
                                        className="small"
                                        onClick={()=>addRemiveItem(e.key)} 
                                        titre={e.title}
                                        variant={selectedEquips.includes(e.key) ? "primary" : ""}    
                                    />)}
                </div>})}
            </Row>
            <Row>
                {Attribut({key:'Accessibilité', size:2, child:<Input_Check label="Oui" value={salle.access} required={false} onChange={e=>setSalle({...salle, access : e})} />})}
                {!(salle.access ?? true) && Attribut({key:"Justification", child:<Input_Text required={!salle.access ?? false} type="text" as="textarea" value={salle.justif} onChange={e=>setSalle({...salle, justif : e})} />})}
            </Row>
            <MButton type='submit' titre="Enregistrer" variant="primary"/>
            <MButton type='reset' titre="Annuler" onClick={()=>goBack()} className="btn-danger"/>
        </Form>
    </div>
</div>;
}
 
export default Salle_Form;