import { Form, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { useEffect, useState } from "react";
import { Input_Select } from "../../components/form/inputSelect";
import { MButton } from "../../components/Buttons/MButton";
import toast from "react-hot-toast";
import { check_is_200, get, post } from "../../Helpers/xhr";
import { useHistory, useParams } from "react-router-dom";
import { all_cities, all_counties } from "../../Helpers/locations";
import { Input_Check } from "../../components/form/inputCheck";
import { useDispatch, useSelector } from "react-redux";
import { InputInt } from "../../components/form/inputInt";
import { update, updateInfo } from "../../app/features/auth/authSlice";

const My_Profile_Form = () => {

    const { userToken } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    
    const [user, setUser] = useState({});
    
    const getData = () => {
        const toastId = toast.loading('Chargement');
          
        post('me_info', userToken)
        .then(xhr => {
            console.log(xhr.data);
            if(check_is_200(xhr) && ('state' in xhr.data))
            {
                if(xhr.data.state)
                {
                    setUser(xhr.data.usr);
                    toast.success(<b>Terminé</b>, { id: toastId, });
                }
                else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            
        });
    }

    useEffect(getData, []);
    
    const [validated, setValidated] = useState(false);
    
    const history = useHistory();
    const goBack = () => history.goBack();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {
            let params = {...user};
            if('tkn' in user)
                params = {...user, usr:user.tkn}

            post('me_upd', userToken, params ).then(xhr => {
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        dispatch(updateInfo(user));
                        goBack();
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
                    
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    return <div>
    <div className="pageHeader">
        <h2 className="pageTitle">Modifier vos informations</h2>    
    </div>
    <div style={{margin:'10px'}}>
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <Row>
                {Attribut({key:'Nom', child:<Input_Text required={true} value={user.nom} onChange={e=>setUser({...user, nom : e})} />})}
                {Attribut({key:'Prénom', child:<Input_Text required={true} value={user.prenom} onChange={e=>setUser({...user, prenom : e})} />})}
            </Row>
            <Row>
                {Attribut({key:'Email', child:<Input_Text required={true} type='email' value={user.email} onChange={e=>setUser({...user, email : e})} />})}
                {Attribut({key:'Tel', child:<Input_Text required={true} type='tel' value={user.tel} onChange={e=>setUser({...user, tel : e})} />})}
            </Row>
            <MButton type='submit' titre="Enregistrer" variant="primary"/>
            <MButton type='submit' titre="Annuler" onClick={()=>goBack()} className="btn-danger"/>
        </Form>
    </div>
</div>;
}
 
export default My_Profile_Form;