import { Switch, Route } from "react-router-dom/cjs/react-router-dom";
import DefaultMenu from "../menu/default";
import Accueil from "./accueil/home";
import { allRoutes } from "../Routes";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";

const Home = () => {
    const { isConnected, userInfo, userToken } = useSelector(
        (state) => state.auth
    );

    //const dispatch = useDispatch()

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            return <Route
                        path={prop.layout + prop.path + (prop.params || '')}
                        render={(props) => <prop.component {...props} />}
                        key={key}
                    />
        });
    };
    
    const ConnectedViews = () => {
        return ( <>
                    <DefaultMenu/>
                    <div className="contentPage">
                        <Switch>
                            {getRoutes(allRoutes(true))}
                            <Route
                                path='/'
                                render={(props) => <Accueil />}
                            />
                        </Switch>
                    </div>
                </> );
    }
    
    const NoConnectedViews = () => {
        return ( <>
                    <div className="contentPage">
                        <Switch>
                            {getRoutes(allRoutes(false))}
                            <Route
                                path='/'
                                render={(props) => <Accueil />}
                            />
                        </Switch>
                    </div>
                </> );
    }
    

    return ( <div>
        {isConnected ? <ConnectedViews/> : <NoConnectedViews/>}
        
        <Toaster
            position="bottom-right"
            reverseOrder={false}        />
    </div> );
}
 
export default Home;