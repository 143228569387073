import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Calendar_Element from "../../components/Calendar/Calendar_Element";

const Salle_Calendar = () => {

    const { salle_key } = useParams();
    
    return ( <>
        <div>
            <Calendar_Element title={"Reservations de la salle"} filterByState salleTkn={salle_key} />
        </div>
    </> );
}
 
export default Salle_Calendar;