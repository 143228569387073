import { Col, Form, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { useEffect, useState } from "react";
import { Input_Select } from "../../components/form/inputSelect";
import { MButton } from "../../components/Buttons/MButton";
import toast from "react-hot-toast";
import { check_is_200, get, post } from "../../Helpers/xhr";
import { useHistory, useParams } from "react-router-dom";
import { all_cities, all_counties } from "../../Helpers/locations";
import { Input_Check } from "../../components/form/inputCheck";
import { useSelector } from "react-redux";
import { InputInt } from "../../components/form/inputInt";
import { InputVal } from "../../components/form/inputVal";
import { currentDate_to_str, datetime_to_str } from "../../Helpers/dates";
import Event_Notes from "./notes";
import { navigateto } from "../../Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCog, faMessage, faPencil, faTimes } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import { filter_search_check_item } from "../../Helpers/DataTableOpt";
import { MyModal } from "../../components/modals/myModal";

const Event_Info = () => {

    const { userToken } = useSelector((state) => state.auth);
    const { event_key } = useParams();
    
    const [isUpdate, setIsUpdate] = useState(false);
    const [inInfra, setInInfra] = useState('true');
    //const [infrastructure, setInfrastructure] = useState([]);
    const [salles, setSalles] = useState([]);

        
    const [event, setEvent] = useState({});
    const [participant, setParticipant] = useState([]);

    useEffect(()=>{
        if(event_key)
        {
            setIsUpdate(true);
            getData(event_key);
        }
    }, [event_key]);


    
    const getParticipants = () => {
        post('event_club_liste', userToken, { eve:event_key })
        .then(xhr => {
            if(check_is_200(xhr) && 'lst' in xhr.data)
                setParticipant(xhr.data.lst ?? [])
        }).catch(function (error){
            
        })
    }
/*
    useEffect(()=>{
        infrastructure.map(inf => { 
            if((inf.selected ?? false))
            {
                [ ... (inf.salles ?? [])].map(sal => {
                    if((sal.selected ?? false))
                    {
                        setEvent({...event, infra:inf.tkn, salle:sal.tkn })
                    }
                })  
            }
        })
    }, [infrastructure])
*/
    
    const getData = () => {
        const toastId = toast.loading('Chargement');
        
        post('event_info', userToken, {eve:event_key, upd:true})
        .then(xhr => {
            if(check_is_200(xhr) && ('even' in xhr.data))
            {
              if(xhr.data.even.insc_asc ?? false) getParticipants();

              setEvent(xhr.data.even);
              setInInfra(xhr.data.even.salle ? 'true' : 'false');
              toast.success(<b>Terminé</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            
        });
    }

    const history = useHistory();
    const goBack = () => history.goBack();
    

    
    const goValidate = (e) => confirmAlert({
        title: "Validation",
        message: "Êtes-vous certain de vouloir valider la demande de la participation à l'événement de l'association "+e.clubNom+" !",
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
                
                const toastId = toast.loading('Traitement');
                post('event_club_accept', userToken, {eve:event_key, tkn:e.tkn}).then(xhr => {
                    if(check_is_200(xhr) && 'state' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setParticipant([...participant].map(p=>p.tkn != e.tkn ? p : {...e, 
                                is_validate:true, 
                                validate_date:xhr.data.date ?? '',
                                stateNom:xhr.data.stateNom ?? '',
                                stateColor:xhr.data.stateColor ?? '',
                            }))
                        }
                        else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else toast.error(<b>Problème de d'envoi</b>, { id: toastId, });                            
                });
            }
          },
          { label: 'Non', }
        ]
    });

    const goDelete = (e) => confirmAlert({
        title: "Suppression de l'événement",
        message: "Êtes-vous certain de vouloir "+(e.is_validate ? "retirer la" : "rejeter la demande de" )+" participation à l'événement de l'association "+e.clubNom+" !",
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
                
                const toastId = toast.loading('Traitement');
                post('event_club_reject', userToken, {eve:event_key, tkn:e.tkn}).then(xhr => {
                    if(check_is_200(xhr) && 'state' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setParticipant(participant.filter(p=>p.tkn != e.tkn))
                        }
                        else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else toast.error(<b>Problème d'envoi</b>, { id: toastId, });                            
                });
            }
          },
          { label: 'Non', }
        ]
    });
    
    const columns = [
        { name:'Titre', sortable:true, selector:r=>r.title??''},
        { name:'Organisateur', sortable:true, selector:r=>r.org??''},
        { name:'Date de début', sortable:true, selector:r=>datetime_to_str(r.dd??'', false)},
        { name:'Date de fin', sortable:true, selector:r=>datetime_to_str(r.df??'', false)},
        { name:'Heure de début', sortable:true, selector:r=>r.hd??''},
        { name:'Heure de fin', sortable:true, selector:r=>r.hf??''},
        { name:'Infrastructure', sortable:true, selector:r=>r.infraNom??''},
        { name:'Salle', sortable:true, selector:r=>r.salleNom??''},
        { name:'Actions', width:'250px', cell:e=><div className="tdIcons">
                                                    <MButton className="small" onClick={()=>navigateto({history, to:'event_update', params:[e.tkn]})} iconb={<FontAwesomeIcon icon={faCog} />} />
                                                    <MButton className="small" onClick={()=>goDelete(e)} iconb={<FontAwesomeIcon icon={faTimes}/>} />
                                                  </div>
        },
    ];

    const mark_message = (title, msg, iconColor='') => <>
        {(msg ?? '').replace(' ', '').length > 0 && <FontAwesomeIcon className="cursor-pointer m-1" icon={faMessage} style={{color:iconColor}} onClick={()=>show_message(title, msg)}/>}
    </>

    const [modal_title, setModal_title] = useState('');
    const [modal_msg, setModal_msg] = useState('');
    const show_message = (title, msg) => {
        setModal_title(title)
        setModal_msg(msg)
    }

    const columnsParticipant = [
        { name:'Club', sortable:true, selector:r=>r.clubNom??''},
        { name:'Date', sortable:true, selector:r=>datetime_to_str(r.date??'')},
        { name:'Commentaire', sortable:true, selector:r=>mark_message('Demande du club '+r.clubNom, r.msg)},
        { name:'Etat', sortable:true, cell:r=><div  className="tdIcons p-3" style={{backgroundColor:r.stateColor}}>
                {r.stateNom}
                {r.is_decline && mark_message("Retrait du club "+r.clubNom, r.decline_msg, 'black')}
            </div>},
        { name:'Valider', sortable:true, selector:r=>datetime_to_str(r.validate_date??'')},
        { name:'Actions', width:'250px', cell:e=><div className="tdIcons">
                {!e.is_validate && <MButton className="small" onClick={()=>goValidate(e)} iconb={<FontAwesomeIcon icon={faCheck} />} />}
                <MButton className="small" onClick={()=>goDelete(e)} iconb={<FontAwesomeIcon icon={faTimes}/>} />
            </div>
        },
    ];


    const [filterKey, setFilterKey] = useState('');

    const les_participants = () => {
        return <>
            
        <DataTable
            columns={columnsParticipant}
            data={participant.filter(item => {
                let filter_key = filterKey.toLocaleLowerCase();
                return filter_search_check_item(filter_key, item, ['clubNom', 'stateNom', 'msg']);
            })}
            pagination
            fixedHeader
            paginationPerPage={40}
            paginationRowsPerPageOptions={[40, 60, 100]}
            noDataComponent="Aucune association !"
            paginationComponentOptions={{
                                            rowsPerPageText: 'Association par page',
                                            rangeSeparatorText: 'sur',
                                            selectAllRowsItem: true,
                                            selectAllRowsItemText: 'Tous',
                                        }}
            />
        </>;
    }

    return <div>
            <div className="pageHeader">
                <div className="pageTitle">Evénement</div>
            </div>
            <div>
                <DataTable
                        className="my-3"
                        columns={columns}
                        data={[event]}
                    />

                {event.commentaire != "" && <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                    {Attribut({key:'Commentaire d\'accès', child:<InputVal value={event.commentaire} />})}
                </div>}

                {event.description != "" && <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                        {Attribut({key:'Description', child:<InputVal value={event.description} />})}
                </div>}

                {(event.insc_asc ?? false ) && <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                    <div className="flex justify-between">
                        <div className="pageSubTitle">Participants</div>
                        <Col sm={3}>
                            <Input_Text value={filterKey} onChange={setFilterKey} placeHolder="Filtrer" className='mb-1'/>
                        </Col>
                    </div>
                    {les_participants()}
                </div>}

                    <Row className="pt-3">
                        {(event.notifmail ?? false) && Attribut({child:<InputVal value="Informer l'ensemble des associations par mail" />})}
                        {(event.notifsms ?? false) && Attribut({child:<InputVal value="Informer l'ensemble des associations par sms" />})}
                    </Row>
                </div>
                <div className="pageSubTitle">Notes</div>
                <Event_Notes the_key={event_key} userToken={userToken} history={history} ajouter />
                
                <MyModal
                    show={modal_title != ""}
                    handleClose={()=>setModal_title('')}
                    title={modal_title}
                    content={<InputVal value={modal_msg}/>}
                />
            </div>;
    }
 
export default Event_Info;