import { faClock, faCog, faMailBulk, faMailForward, faMap, faPhone, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MButton } from "../../components/Buttons/MButton";
import { navigateto } from "../../Routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect, useState } from "react";
import { check_is_200, post } from "../../Helpers/xhr";
import { MyModal } from "../../components/modals/myModal";
import toast from "react-hot-toast";
import { TimeSlotView } from "../../components/form/TimeSlot";
import Mairie_Document_Info from "../Mairie_Document/info";

const Home_Info = () => {

    const { userToken, mairieInfo } = useSelector(state => state.auth);
    
    const history = useHistory();

    const [stats, setStats] = useState({});

    const getData = () => {
        post('mairie_stat_home', userToken ).then(xhr => {
            if(check_is_200(xhr) && 'stats' in xhr.data)
                setStats(xhr.data.stats || {});            
        });
    }

    useEffect(getData, []);

    const Item_Mairie = () => <>
        <div className="content_bloc p-3 border-black border-1">
            <div className="pageSubTitle mb-3">
                <div className="flex justify-between">
                    <div>{mairieInfo.nom}</div>
                    <MButton className="small" onClick={()=>navigateto({history, to:'mairie_form'})} icone={<FontAwesomeIcon icon={faCog}/>}/>
                </div>
            </div>
            <div className="mb-3"><img src={mairieInfo.logo}/></div>
            
            <div className="flex justify-between">
                {(mairieInfo.adresse) && <div><FontAwesomeIcon icon={faMap}/> {mairieInfo.adresse} {mairieInfo.cp}, {mairieInfo.ville}</div>}
                <MButton className="small m-0" iconb={<FontAwesomeIcon icon={faClock}/>} onClick={()=>showTimeSlots("Horaire de la mairie", mairieInfo.horaires)} />
            </div>
        </div>
        <Services/>
        <Mairie_Document_Info userToken={userToken} history={history} />
        <MyModal
                show={selected_Horaire != null}
                content={<TimeSlotView horaires={selected_Horaire}/>}
                title={modalTitle}
                handleClose={()=>setSelected_Horaire(null)}
                buttons={[{'title':"Fermer", 'click':()=>setSelected_Horaire(null)}]}
            />
    </>


    const [modalTitle, setModalTitle] = useState('');
    const [selected_Horaire, setSelected_Horaire] = useState(null);

    
    const showTimeSlots = (title, horaire) => {
        setModalTitle(title)
        setSelected_Horaire(horaire)
    }


    const Services = () => {

        const [services, setServices] = useState([]);

        const getData = () => {
            post('mairie_service_list', userToken )
            .then(xhr=>{
                if( check_is_200(xhr) && 'lst' in xhr.data)
                {
                    setServices(xhr.data.lst);
                }
            });
        }

        useEffect(getData, []);


        return <>
            <>
                <div className="content_bloc p-3 border-black border-1">
                    <div className="pageSubTitle mb-1">
                        <div className="flex justify-between">
                            <div>Services</div>
                            <MButton className="small" onClick={()=>navigateto({history, to:'mairie_services'})} icone={<FontAwesomeIcon icon={faCog}/>}/>
                        </div>
                    </div>
                    {(services && services.length>0) && <>
                        <div>
                            {services.map(service => <div className="content_bloc py-2 px-3 mb-2 border-1 border-gray-400">
                                                        <div className="mb-1 font-bold">
                                                            <div className="flex justify-between">
                                                                <div>{service.nom}</div>
                                                                <MButton className="small m-0" iconb={<FontAwesomeIcon icon={faClock}/>} onClick={()=>showTimeSlots(service.nom, service.horaires)} />
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <div><FontAwesomeIcon size={"sm"} icon={faPhone}/> {service.tel}</div>
                                                            <div><FontAwesomeIcon size={"sm"} icon={faMailBulk}/> {service.email}</div>
                                                        </div>
                                                    </div>)}
                        </div>
                        
                    
                    </>}
                    
                    
                </div>
            </>
            
        </>
    }

    const Item_info = ({ title, number, className, onClick=null }) => <Col onClick={onClick != null ? onClick : ()=>{}} className={onClick != null ? "cursor-pointer":''}>
                <div style={{minHeight:'90px'}} className={"content_bloc pb-0 "+className}>
                    <div className="flex justify-between">
                        <div className="pageSubTitle">{title}</div>
                        <div style={{textAlign:'right', fontSize:'30px', fontStyle:'italic', color:'blue'}}>{number}</div>
                    </div>
                </div>
            </Col>
    return ( <>
        <div className="pageHeader mb-4">
            <div className="pageTitle">Accueil</div>
        </div>
        <div>
            <Row>
                <Col sm={3}>
                    <Item_Mairie/>
                </Col>
                <Col>
                    <Row>
                        <Item_info title="Calendrier" number={stats.calend || null} className="color1_background" onClick={()=>navigateto({ history, to:'calendar_list'})}/>
                        <Item_info title="Compte" number={stats.comp || null} className="color1_background"/>
                        <Item_info title="Affectation" number={stats.affc || null} className="color1_background" onClick={()=>navigateto({ history, to:'affectation_list'})}/>
                    </Row>
                    <Row>
                        <Item_info title="Infrastructure" number={stats.infra || null} className="color1_background" onClick={()=>navigateto({ history, to:'infra_list'})}/>
                        <Item_info title="Demande" number={stats.dem || null} className="color1_background" onClick={()=>navigateto({ history, to:'reservation_waiting'})}/>
                        <Item_info title="Réclamation" number={stats.claim || null} className="color1_background" onClick={()=>navigateto({ history, to:'claim_list'})}/>
                    </Row>
                    <Row>
                        <Item_info title="Evénement" number={stats.even || null} className="color2_background" onClick={()=>navigateto({ history, to:'event_list'})}/>
                        <Item_info title="Association" number={stats.assc || null} className="color1_background" onClick={()=>navigateto({ history, to:'association_list'})}/>
                    </Row>
                </Col>
            </Row>
            
        </div>
    </> );
}
 
export default Home_Info;