import { useEffect, useState } from "react";
import { check_is_200, post } from "../../Helpers/xhr";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Col, Form, Row, Table } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { MButton } from "../../components/Buttons/MButton";
import { getItemRoute, navigateto } from "../../Routes";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Input_Text } from "../../components/form/inputText";
import { Input_Check } from "../../components/form/inputCheck";
import { InputVal } from "../../components/form/inputVal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faArrowLeft, faBackward, faCalendar, faCalendarAlt, faCog, faCogs, faHeadset, faInfo, faMessage, faTimes, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { MyModal } from "../../components/modals/myModal";
import { get_day_of_week_name } from "../../Helpers/Variants";
import DataTable from "react-data-table-component";
import { TimeSlotView } from "../../components/form/TimeSlot";


const Infrastructure_Info = () => {

    const { userToken } = useSelector(state=>state.auth);

    const { infra_key } = useParams();
    const [infra, setInfra] = useState({});
    const [horaires, setHoraires] = useState([]);
    const [salles, setSalles] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');
    
    const getData = () => {
        const toastId = toast.loading('Chargement');
          
        post('infra_info', userToken, {infra:infra_key, 'whrs':true, 'wbr':true})
        .then(xhr => {
            if(check_is_200(xhr) && ('infra' in xhr.data))
            {
              setInfra(xhr.data.infra);
              setHoraires(xhr.data.infra.hours || []);
              toast.success(<b>Terminé</b>, { id: toastId, });
              getSalles();
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            
        });
    }

    const getSalles = () => {
        post('salle_list', userToken, {infra:infra_key, 'wbr':true})
        .then(xhr => {
            if ( check_is_200(xhr) && ('lst' in xhr.data) )
            {
                setSalles(xhr.data.lst);
            }
        }).catch(function (error){
            
        });
    }
  
    const closeShowModal = () => setModalShow(!modalShow);
    const showMessage = (title, msg) => {
      setModalTitle(title);
      setModalContent(msg);
      closeShowModal();
    }

    const showComment = (e) => showMessage("Commentaire d'accès", e.commentaire);
    const showJustif = (e) => showMessage("Détail", e.justif);


    useEffect(()=>{
        if(infra_key) getData();
    }, [])

    
    const history = useHistory();
    const goBack = () => history.goBack();

    const godelete = () => {
        confirmAlert({
            title: "Suppression de l'infrastructure",
            message: 'Êtes-vous certain de cette action !',
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    
                    const toastId = toast.loading('Traitement');

                    post('infra_remv', userToken, {infra:infra_key}).then(xhr => {
                        if(check_is_200(xhr) && 'state' in xhr.data)
                        {
                            if(xhr.data.state)
                            {
                                toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                                goBack();
                            }
                            else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                        else toast.error(<b>Problème de suppression</b>, { id: toastId, });                            
                    });
                }
              },
              { label: 'Non', }
            ]
          });
    }

    const deleteSalle = (e) => {
      confirmAlert({
        title: "Suppression de la salle",
        message: 'Êtes-vous certain de cette action !',
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
                const toastId = toast.loading('Traitement');
                post('salle_remv', userToken, {salle:e.tkn}).then(xhr => {
                    if(check_is_200(xhr) && 'state' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setSalles(salles.filter(i=>i.tkn!=e.tkn))
                        }
                        else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else toast.error(<b>Problème de suppression</b>, { id: toastId, });                            
                });
            }
          },
          { label: 'Non', }
        ]
      });
    }

    const columns = [
        { name:'Nom', selector:r=>r.name??''},
        { name:'Adresse', width:'200px', selector:r=>r.address??''},
        { name:'Code postal', selector:r=>r.cp??''},
        { name:'Ville', selector:r=>r.city??''},
        { name:'Telephone', selector:r=>r.tel??''},
        { name:'Accessibilité', selector:e=><td className="tdIcons">
                                    {e.access ? 'Oui' : 'Non'}
                                    {e.access ? '' : <MButton className='small' iconb={<FontAwesomeIcon icon={faMessage}/>} onClick={()=>showJustif(e)} show={e.justif} />}
                                  </td>},
        { name:'Actions', width:'550px', cell:e=><div className="tdIcons">
                  <MButton className="small" onClick={()=>navigateto({history, to:'infra_update', params:[infra_key ?? '']})} iconb={<FontAwesomeIcon icon={faCog}/>} />
                  <MButton className="small" onClick={()=>navigateto({history, to:'infra_calendar', params:[infra_key ?? '']})} iconb={<FontAwesomeIcon icon={faCalendarAlt}/>} titre="Calendrier"/>
                  <MButton className="small" onClick={()=>navigateto({history, to:'affectation_infra', params:[infra_key ?? '']})} iconb={<FontAwesomeIcon icon={faUsers}/>} titre="Affectations"/>
                  <MButton className="small" onClick={()=>navigateto({history, to:'claim_infra_list', params:[infra_key ?? '']})} iconb={<FontAwesomeIcon icon={faHeadset}/>} titre="Réclamations"/>
                  <MButton className="small" onClick={()=>godelete()} iconb={<FontAwesomeIcon icon={faTimes}/>} />
                </div>
        },
    ];

    
    const columnsSalles = [
      { name:'Nom', sortable:true, selector:r=>r.name??''},
      { name:'Superficie', sortable:true, selector:r=>r.superficie??''},
      { name:'Accessibilité', sortable:true, cell:e=><td className="tdIcons">
                                                  {e.access ? 'Oui' : 'Non'}
                                                  {e.access ? '' : <MButton className='small' iconb={<FontAwesomeIcon icon={faMessage}/>} onClick={()=>showJustif(e)} show={e.justif} />}
                                                </td>},
      { name:'Actions', width:'250px', cell:e=><div className="tdIcons">
                  <MButton className='small' iconb={<FontAwesomeIcon icon={faInfo} />}  onClick={()=>navigateto({history, to:'salle_info', params:[e.tkn ?? '']})}  />
                  <MButton className='small' iconb={<FontAwesomeIcon icon={faCog}/>} onClick={()=>navigateto({history, to:'salle_update', params:[e.tkn ?? '']})}  />
                  <MButton className='small' iconb={<FontAwesomeIcon icon={faMessage}/>} onClick={()=>showComment(e)} show={e.commentaire} />
                  <MButton className="small" iconb={<FontAwesomeIcon icon={faTimes}/>} onClick={()=>deleteSalle(e)}/>
                </div>
      },
  ];


    return ( <div>
      <div className="pageHeader">
        <h2 className="pageTitle">Infrastructure</h2>
        <MButton className="small light" onClick={()=>goBack()} iconb={<FontAwesomeIcon icon={faArrowLeft}/>} titre="Retour"/>
      </div>
      <Row className="mb-4">
        <DataTable
          columns={columns}
          data={[infra]}
          />
      </Row>
      <Row>
          <Col>    
            <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <Row>
                {Attribut({key:"Commentaire d'accès", child:<InputVal type="text" value={infra.commentaire} />})}
            </Row>
            </div>        
          </Col>
          <Col sm={2}>
            <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                <div className="flex flex-wrap">
                  <div className="pageSubTitle">Horaires</div>
                  <MButton className="small" onClick={()=>navigateto({ history, to:'infra_hours', params:[infra_key ?? '']})} iconb={<FontAwesomeIcon icon={faCog}/>} />
              </div>
              <TimeSlotView horaires={horaires}/>
            </div>
          </Col>
        </Row>



        <div className="bg-white rounded shadow-lg p-3 px-3 md:p-8 mb-6">
          <div className="flex flex-wrap pb-3">
              <div className="pageSubTitle">Salles</div>
              <div  style={{margin:'0px 20px'}}>
                <MButton className="small" titre="Ajouter" iconb={<FontAwesomeIcon icon={faAdd}/>} onClick={()=>navigateto({history, to:'salle_add', params:[infra_key]})}/>
              </div>
          </div>
          {salles.length>0 && <DataTable
            columns={columnsSalles}
            data={salles}
          />}
        </div>

      <MyModal
        title={modalTitle}
        handleClose={()=>closeShowModal()}
        show={modalShow}
        content={modalContent}
        contentHtml={true}
      />
    </div> );
}
 
export default Infrastructure_Info;