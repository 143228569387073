import { Form, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { useEffect, useState } from "react";
import { Input_Select } from "../../components/form/inputSelect";
import { MButton } from "../../components/Buttons/MButton";
import toast from "react-hot-toast";
import { check_is_200, get, post } from "../../Helpers/xhr";
import { useHistory, useParams } from "react-router-dom";
import { all_cities, all_counties } from "../../Helpers/locations";
import { Input_Check } from "../../components/form/inputCheck";
import { useSelector } from "react-redux";
import { InputInt } from "../../components/form/inputInt";

const Affectation_Form = ({ forAgent, forInfra, agent_token, infra_token }) => {

    const { userToken } = useSelector(state=>state.auth);
    const [ affectation, setAffectation] = useState({});
    
    const [agents, setAgents] = useState([]);
    const [infrastructures, setInfrastructures] = useState([]);

    const [loading, setLoading] = useState(true);

    const getData = () => {
        post('afct_form_data', userToken, {affectation})
        .then(xhr => {
            if(check_is_200(xhr))
            {
              setAgents(xhr.data.agents ?? []);
              setInfrastructures(xhr.data.infras ?? []);
            }
            else toast.error(<b>Problème de chargement</b>);
        }).catch(function (error){
            toast.error(<b>Problème de chargement</b>);
        }).finally(()=>setLoading(false));
    }

    useEffect(getData, []);

    const [validated, setValidated] = useState(false);
    
    const history = useHistory();
    const goBack = () => history.goBack();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {   
            let params = {...affectation}
            if(forAgent) params = {...params, agent:agent_token}
            if(forInfra) params = {...params, infra:infra_token}

            post('afct_form_save', userToken, params ).then(xhr => {
                
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goBack();
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
                    
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    return <div>
    <div>
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <Row>
                {!forAgent && Attribut({key:'Agent', child:<Input_Select options={agents} fieldTitle="nom" fieldValue="tkn" required={true} value={affectation.agent} onChange={e=>setAffectation({...affectation, agent : e})} />})}
                {!forInfra && Attribut({key:'Infrastructure', child:<Input_Select options={infrastructures} fieldTitle="nom" fieldValue="tkn" required={true} value={affectation.infra} onChange={e=>setAffectation({...affectation, infra : e})} />})}
            </Row>
            <Row>
                {Attribut({key:'Date début', child:<Input_Text required={true} type='date' value={affectation.datestart} onChange={e=>setAffectation({...affectation, datestart : e})} />})}
                {Attribut({key:'Heure début', child:<Input_Text required={true} type='time' value={affectation.timestart} onChange={e=>setAffectation({...affectation, timestart : e})} />})}
                {Attribut({key:'Date fin', child:<Input_Text required={true} type='date' value={affectation.dateend} onChange={e=>setAffectation({...affectation, dateend : e})} />})}
                {Attribut({key:'Heure fin', child:<Input_Text required={true} type='time' value={affectation.timeend} onChange={e=>setAffectation({...affectation, timeend : e})} />})}
            </Row>
            <MButton type='submit' titre="Enregistrer" variant="primary"/>
            <MButton type='submit' titre="Annuler" onClick={()=>goBack()} className="btn-danger"/>
        </Form>
    </div>
</div>;
}

export default Affectation_Form;