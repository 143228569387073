import { Table } from "react-bootstrap";
import { useHistory } from "react-router";
import { MButton } from "../../components/Buttons/MButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faInfo, faPlus, faTimes, faUsers } from "@fortawesome/free-solid-svg-icons";
import { getItemRoute, navigateto } from "../../Routes";
import { Col, Container, Row } from "react-bootstrap";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { check_is_200, deleta, get, packFiles, post, postFormData } from "../../Helpers/xhr";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { MyModal } from "../../components/modals/myModal";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../Helpers/DataTableOpt";
import RequiredItem from "../../Helpers/RequiredItem";
import { InputVal } from "../../components/form/inputVal";
import { Input_Select } from "../../components/form/inputSelect";
import { Input_Check } from "../../components/form/inputCheck";
import FilesUploader from "../../Helpers/fileUploader";

const Mairie_Documents = () => {
    
    const { userToken } = useSelector((state) => state.auth);
    const history = useHistory();
    const [list, setList] = useState([]);
    const [files, setFiles] = useState([]);

    const [contentConfModal, setContentConfModal] = useState('');
    const [showConfModal, setShowConfModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    
    //const account = useSelector((state) => state.account);
    const getData = () => {
        const toastId = toast.loading('Chargement');
            
        post('mairie_doc_list', userToken)
        .then(xhr => {
            if(check_is_200(xhr) && ('lst' in xhr.data))
            {
                console.log(xhr.data.lst);
                setList(xhr.data.lst);
                toast.success(<b>Terminé</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            
        });
    }

    useEffect(getData, []);
    
    const closeShowModal = () => setShowConfModal(false);
    const showForm = (item) => {
        setFiles([]);
        setValidated(false);
        setSelectedItem(item);
        setShowConfModal(true);
    }

    const getForm = () => {
        return <>
                <Row>
                    {Attribut({key:'Nom', child:<Input_Text required={true} value={selectedItem.nom ?? ''} onChange={e=>setSelectedItem({...selectedItem, nom:e})} />})}
                </Row>
                <Row className="mb-3">
                    {Attribut({key:'Fichier', child:<FilesUploader uploadedFiles={files} setUploadedFiles={setFiles} max_files={1} />})}
                    <RequiredItem message="Veuillez choisir un fichier" condition={(!('tkn' in selectedItem) && files.length>0) || ('tkn' in selectedItem)} />
                </Row>
                <Row>
                    {Attribut({child:<Input_Check label="Accessible aux public" value={selectedItem.for_public ?? false} onChange={e=>setSelectedItem({...selectedItem, for_public:e})} />})}
                </Row>
                <Row>
                    {Attribut({child:<Input_Check label="Accessible pour les associations" value={selectedItem.for_club ?? false} onChange={e=>setSelectedItem({...selectedItem, for_club:e})} />})}
                </Row>
                <Row>
                    {Attribut({child:<Input_Check label="Accessible en interne" value={selectedItem.for_local ?? false} onChange={e=>setSelectedItem({...selectedItem, for_local:e})} />})}
                </Row>
            </>;
    }

    const [base64File, setBase64File] = useState(null);
    const show_doc = (item) => {
        window.open(item.file);
        //setBase64File(item);
    }

    
    const handleUpdatePassword = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {
            setShowConfModal(false);
            
            let packed = packFiles(files);
            
            console.log( {...selectedItem, file:Object.keys(packed.obj).join('|')} );

            postFormData('mairie_doc_save', userToken, {...selectedItem, file:Object.keys(packed.obj).join('|')}, packed.data)
            //post('mairie_doc_save', userToken, selectedItem )
            .then(xhr => {
                console.log(xhr.data);
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        
                        if('dto' in xhr.data && xhr.data.dto)  
                        {
                            if('tkn' in selectedItem)
                                setList([...list].map(e=>e.tkn==selectedItem.tkn ? xhr.data.dto : e ));
                            else
                                setList([...list, xhr.data.dto]);
                        }
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
                    
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    
    const add_doc = () => showForm({})
    const update_doc = (item) => showForm(item)

    const delete_doc = (item) => {
        confirmAlert({
            title: "Suppression document",
            message: `Êtes-vous certain de vouloir supprimer le document ${item.nom} !`,
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    const toastId = toast.loading('Traitement');
                    post('mairie_doc_remv', userToken, {tkn:item.tkn}).then(xhr => {
                        if(check_is_200(xhr) && 'state' in xhr.data)
                        {
                            if(xhr.data.state)
                            {
                                toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                                setList([...list].filter(e=>e!=item));
                            }
                            else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                        else toast.error(<b>Problème de suppression</b>, { id: toastId, });                            
                    });
                }
              },
              { label: 'Non', }
            ]
          });
    }

    const [filterKey, setFilterKey] = useState('');

    const columns = [
        { name:'Nom', sortable:true, selector:r=>r.nom??''},
        { name:'Au public', sortable:true, selector:r=>(r.for_public??false) ? 'Oui':'Non'},
        { name:'Au Association', sortable:true, selector:r=>(r.for_club??false) ? 'Oui':'Non'},
        { name:'En interne', sortable:true, selector:r=>(r.for_local??false) ? 'Oui':'Non'},
        { name:'Actions', width:'170px', cell:e=><div className="tdIcons">
                                                    <MButton className="small" onClick={()=>show_doc(e)} iconb={<FontAwesomeIcon icon={faEye} />} />
                                                    <MButton className="small" onClick={()=>update_doc(e)} iconb={<FontAwesomeIcon icon={faCog} />} />
                                                    <MButton className="small" onClick={()=>delete_doc(e)} iconb={<FontAwesomeIcon icon={faTimes}/>} />
                                                  </div>
        },
    ];

    return ( <div>
      <div className="pageHeader">
      <div className="flex justify-between">
        <div className="flex flex-wrap">
            <div className="pageTitle">Document</div>
            
            <div style={{margin:'5px 20px'}}>
              <MButton 
                className="small"
                  nom="Ajouter" 
                  iconb={<FontAwesomeIcon icon={faPlus} />}
                  onClick={()=>add_doc()}
                />
            </div>
        </div>
        <div>
          {Attribut({ isRow:true, contentSize:9, key:"Filtrer", child:<Input_Text value={filterKey} onChange={setFilterKey} />})}
        </div>
      </div>
      </div>
      
      <DataTable
            columns={columns}
            data={list.filter(item => {
                let filter_key = filterKey.toLocaleLowerCase();
                return filter_search_check_item(filter_key, item, ['nom', 'type']);
            })}
            
            pagination
            fixedHeader
            paginationPerPage={40}
            paginationRowsPerPageOptions={[40, 60, 100]}
            noDataComponent="Aucune document !"
            paginationComponentOptions={{
                                            rowsPerPageText: 'document par page',
                                            rangeSeparatorText: 'sur',
                                            selectAllRowsItem: true,
                                            selectAllRowsItemText: 'Tous',
                                        }}
            />
      
            <MyModal
                title="Document"
                handleClose={()=>closeShowModal()}
                show={showConfModal}
                dynamicContent={()=>getForm()}
                isForm={true}
                handleSubmit={handleUpdatePassword}
                validated={validated}
                buttons={[
                    {title:'Enregistrer', variant:'primary', type:'submit'},
                    {title:'Annuler', click:()=>closeShowModal()},
                ]}
            />

            <MyModal
                title="Document"
                handleClose={()=>setBase64File(null)}
                show={base64File != null}
                content={<>
                            {(base64File != null) && <><iframe src={(base64File.file ?? '')} /></>}
                        </>}
                buttons={[
                    {title:'Fermer', click:()=>setBase64File()},
                ]}
            />

    </div> );
}
 
export default Mairie_Documents;