import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Attribut } from "../../components/form/attribut";
import { InputVal } from "../../components/form/inputVal";
import { MButton } from "../../components/Buttons/MButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faShield, faShieldAlt, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { MyModal } from "../../components/modals/myModal";
import { useState } from "react";
import { Input_Text } from "../../components/form/inputText";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import toast from "react-hot-toast";
import { check_is_200, post } from "../../Helpers/xhr";
import RequiredItem from "../../Helpers/RequiredItem";
import DataTable from "react-data-table-component";
import { navigateto } from "../../Routes";

const My_Profile_Info = () => {
    const { userToken, userInfo } = useSelector(state => state.auth)

    const history = useHistory();

    const [modal_show, setModal_show] = useState(false);
    const [validated, setValidated] = useState(false);

    const [curpass, setCurpass] = useState('');
    const [newpass, setNewpass] = useState('');
    const [dnewpass, setDnewpass] = useState('');


    
    const hide_modal = () => setModal_show(false);

    const getForm = () => {
    return <>
                <Row>
                    {Attribut({key:'Mot de passe actuel', child:<Input_Text required={true} type='password' value={curpass} onChange={setCurpass} />})}
                </Row>
                <Row>
                    {Attribut({key:'Nouveau mot de passe', child:<Input_Text required={true} type='password' value={newpass} onChange={setNewpass} />})}
                </Row>
                <Row>
                    {Attribut({key:'Confirmer le nouveau mot de passe', child:<Input_Text required={true} type='password' value={dnewpass} onChange={setDnewpass} />})}
                </Row>
                <RequiredItem condition={newpass==dnewpass} message="Les mots de passe que vous avez entrés ne correspondent pas" />
            </>;
    }

    const init_Form = () => {
        setValidated(false)
        setModal_show(false)
        setCurpass('')
        setNewpass('')
        setDnewpass('')
    }

    const handleUpdatePassword = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {
            setModal_show(false);
            post('me_umdp', userToken, {cur:curpass, mdp:newpass} ).then(xhr => {
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        init_Form()
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });                    
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    const columns = [
        { name:'Nom', selector:r=>r.nom??''},
        { name:'Prénom', selector:r=>r.prenom??''},
        { name:'Tel', width:'130px', selector:r=>r.tel??''},
        { name:'Email', width:'200px', selector:r=>r.email??''},
        { name:'Actions', width:'300px', cell:e=><div className="tdIcons">
                                                    <MButton className="small" onClick={()=>navigateto({history, to:'my_profile_form', params:[e.tkn]})} iconb={<FontAwesomeIcon icon={faCog} />} />
                                                    <MButton className="small" onClick={()=>setModal_show(true)} icone={<FontAwesomeIcon icon={faShieldAlt}/>} titre="Changer mot de passe"/>
                                                  </div>
        },
    ];

    return ( <>
        <div className="pageHeader">
            <div className="pageTitle">Mes informations</div>
        </div>
        <DataTable
            columns={columns}
            data={[userInfo]}
            />
        
        <MyModal
            title={"Changer votre mot de passe"}
            show={modal_show}
            handleClose={hide_modal}
            dynamicContent={getForm}
            isForm={true}
            handleSubmit={handleUpdatePassword}
            validated={validated}
            buttons={[
                {title:'Enregistrer', type:'submit', variant:'primary'},
                {title:'Annuler', type:'button', click:()=>init_Form()},
            ]}
        />
    </> );
}
 
export default My_Profile_Info;