import { Col, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { MButton } from "../../components/Buttons/MButton";
import { useEffect, useState } from "react";
import { check_is_200, packFiles, post, postFormData } from "../../Helpers/xhr";
import toast from "react-hot-toast";
import FilesUploader from "../../Helpers/fileUploader";
import axios from "axios";

const Event_Note_Add = ({ userToken, the_key, onAdded }) => {

    const [Message, setMessage] = useState('');
    const [joinedFiles, setJoinedFiles] = useState([]);
    
    const sendNote = () => {

        let packed = packFiles(joinedFiles);
        console.log({tkn: the_key, msg:Message, byp:'a', imgs:Object.keys(packed.obj).join('|')});
        postFormData('event_addnt', userToken, {tkn: the_key, msg:Message, byp:'a', imgs:Object.keys(packed.obj).join('|')}, packed.data )
        .then(xhr => {
            console.log(xhr.data);
            if(check_is_200(xhr) && 'state' in xhr.data)
            {
                if(xhr.data.state)
                {
                    setMessage('');
                    setJoinedFiles([])
                    if(onAdded) onAdded();
                }
                else toast.error(<b>{xhr.data.msg}</b>);
            }
            else toast.error(<b>Problème d'envoi</b>);
        });
    }

    useEffect(()=>console.log(joinedFiles), [joinedFiles]);

    return ( <>
        <Row>
            <Col>
                {Attribut({key:"Message", child:<Input_Text as='textarea' value={Message} onChange={(e)=>setMessage(e)} />})}
            </Col>
            <Col sm={2}>
                <div style={{marginTop:'37px'}}><MButton onClick={()=>sendNote()} titre="Envoyer" /></div>
            </Col>
        </Row>
        <Row>
            <Col>
                <FilesUploader images uploadedFiles={joinedFiles} setUploadedFiles={setJoinedFiles}/>
            </Col>
        </Row> 
    </> );
}
 
export default Event_Note_Add;