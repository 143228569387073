import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './app/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { PersistGate } from 'redux-persist/integration/react';


let MainPages = () => <BrowserRouter>
                        <App />
                      </BrowserRouter>;

let MainPagesWithSession = () => <Provider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                    <MainPages/>
                  </PersistGate>
                </Provider>;

let MainPagesWithStore = () => <Provider store={store}>
                    <MainPages/>
                </Provider>;



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<MainPagesWithSession/>);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
