import { Col, Form, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { Input_Text, handle_change_input_objet_in_array } from "../../components/form/inputText";
import { useEffect, useState } from "react";
import { MButton } from "../../components/Buttons/MButton";
import { goBack } from "../../Routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import toast from "react-hot-toast";
import { check_is_200, packFiles, post, postFormData } from "../../Helpers/xhr";
import { useDispatch, useSelector } from "react-redux";
import { updateMairie } from "../../app/features/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faTimes } from "@fortawesome/free-solid-svg-icons";
import List_Add_Remove from "../../components/list/List_Add_Remove";
import FilesUploader from "../../Helpers/fileUploader";
import { TimeSlotForm } from "../../components/form/TimeSlot";

const Mairie_Form = () => {

    const { userToken, mairieInfo } = useSelector(state=>state.auth);
    const dispatch = useDispatch();

    const [mairie, setMairie] = useState(mairieInfo);

    const history = useHistory();

    const [validated, setValidated] = useState(false);

    const getData = () => {
        post('mairie_info', userToken ).then(xhr => {
            if(check_is_200(xhr) && 'state' in xhr.data && 'mairie' in xhr.data)
            {
                if(xhr.data.state)
                {
                    console.log(xhr.data.mairie);
                    setMairie(xhr.data.mairie);
                }
                else toast.error(<b>{xhr.data.msg}</b>);
            }
            else toast.error(<b>Problème d'envoi</b>);
        });
    }

    useEffect(()=>getData(), []);
    
    const HandleSubmit = (event) => {
        let form = event.currentTarget;
        event.preventDefault();

        setValidated(true);

        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {
            let packed = packFiles(logo);
            postFormData('mairie_save', userToken, {...mairie, tkn:mairie.auth, logo:Object.keys(packed.obj).join('|')}, packed.data )
            //post('mairie_save', userToken, {...mairie, tkn:mairie.auth} )
            .then(xhr => {
                console.log(xhr.data)
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        dispatch(updateMairie({...mairie, logo:xhr.data.logo ?? ''}));
                        goBack();
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    const goBack = () => history.goBack();

    const [logo, setLogo] = useState([]);
    
    return ( <>
            <div className="pageHeader">
                <div className="pageTitle">Mairie</div>
            </div>
            <div className="content_bloc">
                <Form noValidate validated={validated} onSubmit={HandleSubmit}>
                    <Row>
                        <Col>
                            {Attribut({ key:"Nom", child:<Input_Text required={true} value={mairie.nom ?? ""} onChange={(e)=>setMairie({...mairie, nom:e})} />})}
                            {Attribut({ key:"Adresse", child:<Input_Text required={true} value={mairie.adresse ?? ""} onChange={(e)=>setMairie({...mairie, adresse:e})} />})}
                            <Row>
                                {Attribut({ size:2, key:"Code postale", child:<Input_Text required={true} value={mairie.cp ?? ""} onChange={(e)=>setMairie({...mairie, cp:e})} />})}
                                {Attribut({ size:2, key:"Ville", child:<Input_Text required={true} value={mairie.ville ?? ""} onChange={(e)=>setMairie({...mairie, ville:e})} />})}
                            </Row>
                            {Attribut({ size:4, key:"Logo", child:<FilesUploader images max_files={1} uploadedFiles={logo} setUploadedFiles={setLogo}/>})}
                            <div className="my-5">
                                <MButton className="mx-0" titre="Enregistrer" type="submit" variant="primary" />
                                <MButton titre="Annuler" onClick={goBack}/>
                            </div>
                        </Col>
                        <Col sm={3}>
                            {Attribut({ key:'Horaires', child:<TimeSlotForm horaires={mairie.horaires ?? []} setHoraires={hrs=>setMairie({...mairie, horaires:hrs})}/> })}
                        </Col>
                    </Row>
                    
                </Form>
            </div>
    </> );
}
 
export default Mairie_Form;