import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Reclamation_Form from "./form";

const Reclamation_Salle_Form = () => {
    const { infra_key } = useParams();

    return ( <>
        <Reclamation_Form forInfra infraTkn={infra_key} />
    </> );
}
 
export default Reclamation_Salle_Form;