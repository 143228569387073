export function sort_function_by(the, type="str")
{
    const caseInsensitiveSort = (rowA, rowB) => {
        let a = rowA[the];
        let b = rowA[the];
        switch(type)
        {
            case 'int': a = parseInt(a); b = parseInt(b); break; 
            case 'float': a = parseFloat(a); b = parseFloat(b); break;
            default:
                a = (rowA[the]===false ? '' : rowA[the]+'').toLowerCase();
                b = (rowB[the]===false ? '' : rowB[the]+'').toLowerCase();

        }
        
        let rtrn = a > b ? 1 : ( b > a ? -1 : 0);
        console.log(a, b, the, rtrn);
        return rtrn;
    };
    return caseInsensitiveSort;
}

export function filter_search_check_item(search_key, item, keys)
{
    for(let key of keys)
    {
        let value = "";

        if ( typeof key == "string" && ( key in item ) ) value = item[key]+''; 
        else if ( typeof key == "object" && ( 'k' in key ) && ( 'f' in key ) && ( key.k in item ) )
        {
            value = key.f(item[key.k])+'';
        } 
        

        if( value.toLocaleLowerCase().includes(search_key) ) 
            return true;
    }
    return false;
}