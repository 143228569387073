import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import frLocale from '@fullcalendar/core/locales/fr';
import interactionPlugin from "@fullcalendar/interaction";
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { check_is_200, post } from '../../Helpers/xhr';
import { useSelector } from 'react-redux';

import { Button, Col, Row } from 'react-bootstrap';
import { Input_Text } from '../form/inputText';
import { Attribut } from '../form/attribut';
import { Input_Select } from '../form/inputSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfo } from '@fortawesome/free-solid-svg-icons';
import { stylePageTitle } from '../../Helpers/styleConfig';
import { MButton } from '../Buttons/MButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { navigateto } from '../../Routes';

const Calendar_Element = ({ 
                msg, title,
                stateTkn, infraTkn, salleTkn, agentTkn, clubTkn, 
                filterByState, filterByInfra, filterBySalle, filterByAgent, filterByClub, 
        }) => {

    const eventss = [
        { id:'dede1', title: 'Meeting Meeting Meeting Meeting Meeting', start: new Date() },
        { id:'dede2', title: 'Meeting', start: new Date("February 13, 2024 19:10:09") },
    ]

    const history = useHistory();

    const [getFilter, setGetFilter] = useState(filterByState || filterByInfra  || filterBySalle || filterByAgent || filterByClub);

    const { userToken } = useSelector((state) => state.auth);
    const [events, setEvents] = useState(eventss);
    const [eventBrut, setEventBrut] = useState([]);

    const [allLoadedEvents, setAllLoadedEvents] = useState({});
    const [loadingEven, setLoadingEven] = useState(false);
    
    const [selectedEven, setSelectedEven] = useState();
    const [selectedEvenClub, setSelectedEvenClub] = useState({});
    const [selectedEvenHoraire, setSelectedEvenHoraire] = useState({});
    const [selectedEvenSalle, setSelectedEvenSalle] = useState({});
    const [selectedEvenInfra, setSelectedEvenInfra] = useState({});
    const [selectedEvenDate, setSelectedEvenDate] = useState({});

    const [filterParams, setFilterParams] = useState({});

    const [state_list, setState_list] = useState([]);
    const [infra_list, setInfra_list] = useState([]);
    const [salle_list, setSalle_list] = useState([]);
    const [agent_list, setAgent_list] = useState([]);
    const [club_list, setClub_list] = useState([]);

    const getData = (arg = {}) => {
        const toastId = toast.loading('Chargement');
        
        post('calendar_reservation', userToken, arg)
        .then(xhr => {
            if(check_is_200(xhr) && ('lst' in xhr.data))
            {
                setEventBrut(xhr.data.lst);
                toast.success(<b>Terminé</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            
        });
    }
    
    useEffect(()=>{
        setEvents(eventBrut.map(e=>{
            return {id:e.tkn, title: e.club, start: new Date( e.date+'T'+e.heureDebut), end: new Date( e.date+'T'+e.heureFin), color:e.color };
         }))
    }, [eventBrut]);

    //useEffect(()=>getData(), []);
    
    const adapt_to_two = (val) => (val< 10 ? '0' : '') + '' + val;

    
    function renderEventContent(eventInfo) {
        let end = new Date(eventInfo.event.end);
        let endStr = adapt_to_two(end.getHours()) +':'+ adapt_to_two(end.getMinutes());
        return (
          <div className='eventCld' style={{backgroundColor:eventInfo.backgroundColor}}>
            <b>{eventInfo.timeText} - {endStr}</b>
            <br/>
            <i>{eventInfo.event.title}</i>
          </div>
        )
    }

    const handleDateClick = (arg) => { // bind with an arrow function
        //alert(arg.dateStr)
    }

    const handleDayClick = (date, jsEvent, view) => {
        alert('Clicked on: ' + date.format());
        alert('Coordinates: ' + jsEvent.pageX + ',' + jsEvent.pageY);
        alert('Current view: ' + view.name);
        // change the day's background color just for fun
        // $(this).css('background-color', 'red');
    }

    const handleEventClick = (e) => getEvenData(e);

    const getEvenData = (e) => {
        setLoadingEven(true);
        setSelectedEven(null);
        /*
        if(evenToken in allLoadedEvents)
        {
            setSelectedEven(allLoadedEvents[evenToken]);
            setLoadingEven(false);
        }
        else
        */
        post('calendar_even_info', userToken, {eve:e.event.id, start:e.event.startStr, end:e.event.endStr})
        .then(xhr => {
            if(check_is_200(xhr) && ('info' in xhr.data))
            {
                setSelectedEven(xhr.data.info ?? {});
                setSelectedEvenClub(xhr.data.club ?? {});
                setSelectedEvenHoraire(xhr.data.horaire ?? {});
                setSelectedEvenDate(xhr.data.date ?? {})
                setSelectedEvenInfra(xhr.data.infra ?? {});
                setSelectedEvenSalle(xhr.data.salle ?? {});

                //setAllLoadedEvents({...allLoadedEvents, evenToken : xhr.data});
            }
            else
            {
                toast.error(<b>Problème de chargement</b>);
                setSelectedEven(null);
            } 
        }).finally(() => {
            setLoadingEven(false);
        });
    }

    const getFilterParams = () => {
        let args = { get: [] }
        if(infraTkn)    args = {...args, infra: infraTkn }
        if(salleTkn)    args = {...args, salle: salleTkn }
        if(agentTkn)    args = {...args, agent: agentTkn }
        if(clubTkn)     args = {...args, club: clubTkn }
        
        if(filterByState) args.get.push('type');
        if(filterByInfra) args.get.push('infra');
        if(filterBySalle) args.get.push('salle');
        if(filterByAgent) args.get.push('agent');
        if(filterByClub)  args.get.push('club');
        
        post('calendar_params', userToken, args)
        .then(xhr => {
            if( check_is_200(xhr) )
            {
                let args = xhr.data;
        
                setState_list(args.type ?? []);
                setInfra_list(args.infra ?? []);
                setSalle_list(args.salle ?? []);
                setAgent_list(args.agent ?? []);
                setClub_list(args.club ?? []);
            }
        });
    }

    useEffect(()=>{
        let inf = infra_list.filter(e=>e.id == filterParams.infra);    
        if( inf.length > 0 && 'salles' in inf[0])   setSalle_list(inf[0].salles || []);
    }, [filterParams.infra ?? '']);

    useEffect(()=>{
        
        let params = {
            type: filterParams.type ?? stateTkn,
            infra: filterParams.infra ?? infraTkn,
            salle: filterParams.salle ?? salleTkn,
            agent: filterParams.agent ?? agentTkn,
            club: filterParams.club ?? clubTkn
        };
        
        let asArray = Object.entries(params);
        let filtered = asArray.filter(([key, value]) => typeof value === 'string' && value != undefined);
        getData(Object.fromEntries(filtered));
        //getData(filterParams);
    }, [filterParams]);
    //useEffect(()=>{ console.log('*')}, [filterParams]);

    useEffect(getFilterParams, []);

    const FilterForm = () => {
        return <>
            {filterByState && Attribut({ key:"Etat réservation",child:<Input_Select keepDefault={true} default_opt_title='Toutes' value={filterParams.type ?? null} onChange={(e)=>setFilterParams({...filterParams, type:e})} options={state_list} />})}
            {filterByInfra && Attribut({ key:"Infrastructure",  child:<Input_Select keepDefault={true} default_opt_title='Toutes' value={filterParams.infra ?? null} onChange={(e)=>setFilterParams({...filterParams, infra:e, salle:null})} options={infra_list} fieldTitle='nom' fieldValue='id' />})}
            {filterBySalle && Attribut({ key:"Salle",           child:<Input_Select keepDefault={true} default_opt_title='Toutes' value={filterParams.salle ?? null} onChange={(e)=>setFilterParams({...filterParams, salle:e})} options={salle_list} fieldTitle='nom' fieldValue='id' />})}
            {filterByAgent && Attribut({ key:"Agent",           child:<Input_Select keepDefault={true} default_opt_title='Tous' value={filterParams.agent ?? null} onChange={(e)=>setFilterParams({...filterParams, agent:e})} />})}
            {filterByClub && Attribut({  key:"Club",            child:<Input_Select keepDefault={true} default_opt_title='Tous' value={filterParams.club ?? null}  onChange={(e)=>setFilterParams({...filterParams, club:e})}  options={club_list} fieldTitle='nom' fieldValue='id' />})}
        </>
    }

    const Legend = () => {
        return <>
            <h1 className="text-2xl font-extrabold dark:text-white">Légende</h1>
            <div className='m-1 rounded p-1 text-center' style={{backgroundColor:'#a6f69f'}}>Validé</div>
            <div className='m-1 rounded p-1 text-center' style={{backgroundColor:'#ffd565'}}>En attente</div>
        </>
    }

    const InfoLine = ({title, value, className, icon}) => <Row className={className}><Col sm={5}>{title}</Col><Col>: {value} {icon}</Col></Row>
    return ( <>{msg}
                <Row>
                    {getFilter && <>
                    <Col sm={2}>
                            {title && <h1 className='pageTitle'>{title}</h1>}
                            <Row className={title ? "mt-[20px]" : "mt-[60px]"}>
                                <div className='rounded border-1 border-neutral-300 p-3 m-1' >
                                    {FilterForm()}
                                </div>
                                <div className='rounded border-1 border-neutral-300 p-3 m-1' >
                                    {Legend()}
                                </div>
                            </Row>
                        </Col></>}
                    {selectedEven && <Col className='mt-[60px]' sm={3}>
                        <div className='rounded border-1 border-neutral-300 p-3 m-1 mb-2'>
                            <div className='flex space-x-4 mb-2'>
                                <h1 className="text-2xl font-extrabold dark:text-white">Informations</h1>
                                <FontAwesomeIcon className='px-5 py-2 hover:text-red-600 cursor-pointer' icon={faClose} onClick={()=>setSelectedEven(null)}/>
                            </div>
                            <div className='rounded border-1 border-neutral-300 p-1 mb-2 color1_background' >
                                {selectedEvenInfra && <>
                                        <InfoLine 
                                            title={'Infrastructure'} value={selectedEvenInfra.nom} 
                                            icon={<MButton className='px-2 py-0 m-0' iconb={<FontAwesomeIcon icon={faInfo}/>} onClick={()=>navigateto({ history, to:'infra_info', params:[selectedEvenInfra.tkn]})}/>}
                                        />
                                        {(selectedEvenInfra.info ?? []).map((e, k)=><InfoLine key={k} title={e.k} value={e.v} />)}
                                    </>}
                                    
                                {selectedEvenSalle && <>
                                        <InfoLine title={'Salle'} value={selectedEvenSalle.nom}  
                                            icon={<MButton className='px-2 py-0 m-0' iconb={<FontAwesomeIcon icon={faInfo}/>} onClick={()=>navigateto({ history, to:'salle_info', params:[selectedEvenSalle.tkn]})}/>}
                                        />
                                        {(selectedEvenSalle.info ?? []).map((e, k)=><InfoLine key={k} title={e.k} value={e.v} />)}
                                    </>}
                            </div>
                            <div className='rounded border-1 border-neutral-300 p-1 color1_background' >
                                {selectedEvenClub && <>
                                        <InfoLine title={'Club'} value={selectedEvenClub.nom} className='text-1xl font-extrabold dark:text-white'/>
                                        {(selectedEvenClub.info ?? []).map((e, k)=><InfoLine key={k} title={e.k} value={e.v} />)}
                                    </>}
                            </div>
                        </div>
                        </Col>}
                    <Col>
                        <FullCalendar
                            locale={frLocale}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView='dayGridMonth'
                            headerToolbar={{
                                left: 'title',
                                right: 'prev,next today'
                              }}
                            events={events}
                            eventClick={(e)=>handleEventClick(e)}
                            eventTimeFormat= {{  hour: '2-digit', minute: '2-digit', meridiem: false }}
                            dateClick={(e)=>handleDateClick(e)}
                            eventContent={renderEventContent}
                            dayClick={handleDayClick}
                            scrollTimeReset={false}
                        />
                    </Col>
                </Row>
    </> );
}
 
export default Calendar_Element;