import { useEffect, useState } from "react";
import { MButton } from "../components/Buttons/MButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ImagesModals from "./imagesModals";

const FilesUploader = ({ max_files=5, uploadedFiles, setUploadedFiles, images }) => {
    
    const [fileLimit, setFileLimit] = useState(false);
    const [inputFileVal, setInputFileVal] = useState('');


    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === max_files) setFileLimit(true);
                if (uploaded.length > max_files) {
                    alert(`Vous ne pouvez ajouter qu'au maximum ${max_files} ${images?'images':'fichiers'}`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)
        setInputFileVal('');
    }

    //useEffect(()=>updateFiles(uploadedFiles), [uploadedFiles]);

    const handleFileEvent =  (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
    }


    const removeFile = (file) => {
        let lst = [...(uploadedFiles.filter(f=>f!=file))];
        setUploadedFiles(lst)
        setFileLimit(lst.length >= max_files);
    }

    const FileItem = (file) =>  images 
    
                            ?   <div className="imagePicker">
                                    <img src={URL.createObjectURL(file)}/>
                                    <MButton onClick={()=>removeFile(file)} titre={<FontAwesomeIcon icon={faTrash} />}/>
                                </div>
                            :   <div>
                                    {file.name}
                                    <MButton onClick={()=>removeFile(file)} titre={<FontAwesomeIcon icon={faTrash} />}/>
                                </div>
    
    return (
		<div className="">
			<input id='fileUpload' type='file' multiple={max_files>1}
					accept={images?'image/*':''}
                    value={inputFileVal}
                    onChange={handleFileEvent}
                    disabled={fileLimit}
			/>

			<div className="joined-files-list">
				{uploadedFiles.map((file, k) => FileItem(file, k))}
			</div>
		</div>
	);
}
 
export default FilesUploader;