import { Form, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { useEffect, useState } from "react";
import { Input_Select } from "../../components/form/inputSelect";
import { MButton } from "../../components/Buttons/MButton";
import toast from "react-hot-toast";
import { check_is_200, get, post } from "../../Helpers/xhr";
import { useHistory, useParams } from "react-router-dom";
import { all_cities, all_counties } from "../../Helpers/locations";
import { Input_Check } from "../../components/form/inputCheck";
import { useSelector } from "react-redux";
import { InputInt } from "../../components/form/inputInt";

const Event_Form = () => {

    const { userToken } = useSelector((state) => state.auth);
    const { event_key } = useParams();
    
    const [isUpdate, setIsUpdate] = useState(false);
    const [inInfra, setInInfra] = useState('true');
    const [infrastructure, setInfrastructure] = useState([]);
    const [salles, setSalles] = useState([]);
        
    const [event, setEvent] = useState({});
    
    useEffect(()=>{
        if(event_key)
        {
            setIsUpdate(true);
            getData(event_key);
        }
    }, [event_key]);


    
    const getItems = () => {
    
        post('event_items', userToken, { eve:event_key })
        .then(xhr => {
            if(check_is_200(xhr))
            {
                setInfrastructure(xhr.data.salles ?? [])
            }
        }).catch(function (error){
            
        })
    }

    useEffect(()=>{
        infrastructure.map(inf => { 
            if((inf.selected ?? false))
            {
                [ ... (inf.salles ?? [])].map(sal => {
                    if((sal.selected ?? false))
                    {
                        setEvent({...event, infra:inf.tkn, salle:sal.tkn })
                    }
                })  
            }
        })
    }, [infrastructure])

    useEffect(getItems, []);

    useEffect(()=>{
        let infra = infrastructure.filter(e=>e.tkn == event.infra);
        let salles = infra.length > 0 ? infra[0].salles : [];
        setSalles( salles );

        setEvent({...event, salle: ( salles.filter(e=>e.tkn == event.salle).length > 0 ? event.salle : null ) })
    }, [event.infra ?? ''])

    const getData = () => {
        const toastId = toast.loading('Chargement');
          
        post('event_form', userToken, {eve:event_key, upd:true})
        .then(xhr => {
            if(check_is_200(xhr) && ('even' in xhr.data))
            {
              setEvent(xhr.data.even);
              setInInfra(xhr.data.even.salle ? 'true' : 'false');
              toast.success(<b>Terminé</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            
        });
    }

    const [validated, setValidated] = useState(false);
    
    const history = useHistory();
    const goBack = () => history.goBack();

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {
            let params = {...event, inInfra};
            if('tkn' in event)
                params = {...params, eve:event.tkn}

            post('event_save', userToken, params ).then(xhr => {
                console.log(xhr);
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goBack();
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
                    
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    return <div>
    <div className="pageHeader">
        <div className="pageTitle">{isUpdate ? 'Modification' : 'Nouvel'} événement</div>
    </div>
    <div style={{margin:'10px'}}>
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <Row>
                {Attribut({key:'Titre', child:<Input_Text required={true} value={event.title} onChange={e=>setEvent({...event, title : e})} />})}
                {Attribut({key:'Organisateur', child:<Input_Text required={true} value={event.org} onChange={e=>setEvent({...event, org : e})} />})}
            </Row>
            <Row>
                {Attribut({key:'Date début', child:<Input_Text required={true} type='date' value={event.dd} onChange={e=>setEvent({...event, dd : e})} />})}
                {Attribut({key:'Date fin', child:<Input_Text required={true} type='date' value={event.df} onChange={e=>setEvent({...event, df : e})} />})}
                {Attribut({key:'Heure début', child:<Input_Text required={true} type='time' value={event.hd} onChange={e=>setEvent({...event, hd : e})} />})}
                {Attribut({key:'Heure fin', child:<Input_Text required={true} type='time' value={event.hf} onChange={e=>setEvent({...event, hf : e})} />})}
            </Row>
            <Row>
                {Attribut({key:'Lieu', size:3, child:<Input_Select value={inInfra} options={[{value:'false',title:'Adresse ponctuelle'}, {value:'true',title:'Infrastructure'}]} onChange={(e)=>setInInfra(e)} />})}
                {inInfra=='false' && <>
                    {Attribut({key:'Adresse', size:6, child:<Input_Text required={inInfra=='false'} value={event.adresse} onChange={e=>setEvent({...event, adresse : e})} />})}
                    {Attribut({key:'Code postal', child:<Input_Text required={inInfra=='false'} value={event.cp} onChange={e=>setEvent({...event, cp : e})} />})}
                    {Attribut({key:'Ville', child:<Input_Text required={inInfra=='false'} value={event.ville} onChange={e=>setEvent({...event, ville : e})} />})}
                </>}
                {inInfra=='true' && <>
                    {Attribut({key:'Infrastructure', child:<Input_Select required={inInfra=='true'} options={infrastructure} onChange={e=>setEvent({...event, infra : e})} value={event.infra ?? ''} fieldTitle="name" fieldValue="tkn" />})}
                    {Attribut({key:'Salle', child:<Input_Select required={inInfra=='true'} options={salles} onChange={e=>setEvent({...event, salle : e})} value={event.salle ?? ''} fieldTitle="name" fieldValue="tkn" />})}
                </>}
            </Row>
            <Row>
                {Attribut({key:'Commentaire d\'accès', child:<Input_Text required={true} as='textarea' value={event.commentaire} onChange={e=>setEvent({...event, commentaire : e})} />})}
            </Row>
            <Row>
                {Attribut({key:'Description', child:<Input_Text required={true} as='textarea' value={event.description} onChange={e=>setEvent({...event, description : e})} />})}
            </Row>
            <Row className="py-3">
                {Attribut({child:<Input_Check required={false} value={event.insc_asc ?? false} label="Ouvrir l'inscription des associations" onChange={e=>setEvent({...event, insc_asc : e})} />})}
                {Attribut({child:<Input_Check required={false} value={event.notifmail ?? false} label="Informer l'ensemble des associations par mail" onChange={e=>setEvent({...event, notifmail : e})} />})}
                {Attribut({child:<Input_Check required={false} value={event.notifsms ?? false} label="Informer l'ensemble des associations par sms" onChange={e=>setEvent({...event, notifsms : e})} />})}
            </Row>
            <MButton type='submit' titre="Enregistrer" variant="primary" className="m-0"/>
            <MButton type='submit' titre="Annuler" onClick={()=>goBack()} className="btn-danger"/>
        </Form>
    </div>
</div>;
}
 
export default Event_Form;