import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { navigateto } from "../../Routes";
import { MButton } from "../../components/Buttons/MButton";
import { check_is_200, post } from "../../Helpers/xhr";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Table } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import Affectation_Table from "./table";

const Affectation_List = () => {
    
    const { userToken } = useSelector((state) => state.auth);
    const history = useHistory();
    
    return ( <>
            <Affectation_Table title={"Affectation"} addFunction={()=>navigateto({history, to:'affectation_add' })}  isGlobal  />
        </> );
}
 
export default Affectation_List;