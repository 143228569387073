import Affectation_Form from "./form";

const Affectation_Add = () => {
    return ( <>
        <div className="pageHeader">
            <div className="pageTitle">Nouvelle affectation</div>
        </div>
        <Affectation_Form/>
    </> );
}
 
export default Affectation_Add;