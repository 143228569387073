import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get_day_of_week_name } from "../../Helpers/Variants";
import { faAdd, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Input_Text } from "./inputText";
import { MButton } from "../Buttons/MButton";

const TimeSlotForm = ({ horaires=null, setHoraires}) => 
{
    if(horaires.length == 0) setHoraires([...Array(7).keys()].map(i=>{ return {jour:i+1, hours:[]}} ))

    const showHoraire = (hour, ind, jr) => <tr className="flex flex-wrap">
        <td><Input_Text required type='time' value={hour.start} onChange={(e)=>change_hour('s', jr, ind, e)} /></td>
        <td><Input_Text required type='time' value={hour.end} onChange={(e)=>change_hour('e', jr, ind, e)} /></td>
        <td><MButton iconb={<FontAwesomeIcon icon={faTimes}/>} className="small" onClick={()=>remove_hour(jr, ind)}/></td>
    </tr>


    const change_hour = (type, jr, hr, val) => {
        let hrs = [...horaires];
        hrs[jr].hours[hr][type == 's' ? 'start' : 'end'] = val;
        setHoraires(hrs);
    }


    const add_hour = (jr) => {
        let hrs = [...horaires];
        hrs[jr].hours.push({'start':'', 'end':''});
        setHoraires(hrs);
    }
    
    const remove_hour = (jr, hr) => {
        let hrs = [...horaires];
        hrs[jr].hours.splice(hr, 1);
        setHoraires(hrs);
    }


    return ( <>
        <table>
            <tbody>
                {horaires.map((e, jr)=><tr key={jr} className="border-b-2">
                    <td className="flex justify-between p-2">
                        <div className="mt-1">{get_day_of_week_name( e.jour )}</div>
                        <MButton iconb={<FontAwesomeIcon icon={faAdd}/>} className="small" onClick={()=>add_hour(jr)}/>
                    </td>
                    <td>
                        {e.hours.length == 0 ? '' : <table><tbody>{e.hours.map((hour, hr)=>showHoraire(hour, hr, jr))}</tbody></table>}
                    </td>
                </tr>)}
            </tbody>
        </table>
    </> );
}
 

const TimeSlotView = ({ horaires }) => {
    return ( <>
        {horaires.map((e)=><><hr/><div className="flex justify-between">
                <div>{get_day_of_week_name(e.jour)}</div>
                <div>{e.hours.length == 0 ? "Fermé" : e.hours.map(e=><div>{e.start} - {e.end}</div>)}</div>                
              </div></>)}
    </> );
}
 
export { TimeSlotForm, TimeSlotView };