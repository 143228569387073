import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { check_is_200, post } from "../../Helpers/xhr";
import { useEffect, useState } from "react";
import Mairie_Club_Documents from "./documents";


const Association_info = () => {
    
    const { userToken } = useSelector((state) => state.auth);
    const { association_key } = useParams();

    const [club, setClub] = useState({});


    const getData = () => {
        post('mairie_asc_info', userToken, { tkn : association_key})
        .then(xhr =>{
            console.log(xhr.data);
            if( check_is_200(xhr) && 'club' in xhr.data )
            {
                setClub(xhr.data.club);
            }
        });
    }

    useEffect(getData, []);

    return ( <>
        <div className="pageHeader">
            <div className="pageTitle">Association</div>
        </div>

        {club.nom ?? ''}

        <Mairie_Club_Documents club_key={association_key} />
    </> );
}
 
export default Association_info;