import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import frLocale from '@fullcalendar/core/locales/fr';
import interactionPlugin from "@fullcalendar/interaction";
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { check_is_200, post } from '../../Helpers/xhr';
import { useSelector } from 'react-redux';

import { Button, Col, Row } from 'react-bootstrap';
import { Input_Text } from '../form/inputText';
import { Attribut } from '../form/attribut';
import { Input_Select } from '../form/inputSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faClose, faTimes } from '@fortawesome/free-solid-svg-icons';
import { stylePageTitle } from '../../Helpers/styleConfig';
import { MButton } from '../Buttons/MButton';
import { get_day_of_week_name } from '../../Helpers/Variants';

const Calendar_Affectation = ({ title, addFunction, resources, events, forAgent, forInfra, activeGroup }) => {

    const [groupBy, setGroupBy] = useState(activeGroup ? 'i' : '');

    const Event = ({ event })=>{
        console.log(event);
        const { type, name, phase, owner, integrator } = event.extendedProps;
        return <>{name}</>
    }

    const Columns = () => {
        let colum = [];


        
        if(!forAgent) colum.push({
            group: groupBy=='a',
            field: 'agent',
            headerContent: 'Agent'
        });
        if(!forInfra) colum.push({
            group: groupBy=='i',
            field: 'infra',
            headerContent: 'Infrastructure'
        });
        
        console.log('columns', forAgent, forInfra, colum);

        return colum;
    }

    const show_popup = (e) => {

        let event = events.find(l=>l.id == e.event.id);
        console.log(event);
        let resrc = resources.find(r => r.id = event.resourceId); 
        toast.custom((t) => (
            <div
              className={`${
                t.visible ? 'animate-enter' : 'animate-leave'
              } max-w-md w-full bg-red-100 border-solid	border-2 border-red-400 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className='flex justify-between'>
                    <div><b>Agent : </b>{resrc.agent}</div>
                    <div>Tel : {resrc.agentTel}</div>
                </div>
                <div className='flex justify-between'>
                    <div><b>Infra : </b>{resrc.infra}</div>
                    <div>Tel : {resrc.infraTel}</div>
                </div>
                <div className='flex justify-between'>
                    <div><b>Période : </b>{get_day_of_week_name(event.day)}, {event.time}</div>
                </div>
              </div>
              <div className="flex m-2 cursor-pointer" onClick={() => toast.dismiss(t.id)} >
                  <FontAwesomeIcon icon={faTimes}/>
              </div>
            </div>
          ))
    }

    return ( <>
        <Col sm={6} className='flex justify-between'>
                {title && <div className='pageTitle'>{title}</div>}
                <div className='flex flex-wrap'>
                  {activeGroup && <div style={{margin:'5px 5px'}}>{Attribut({isRow:true, key:null, child:<Input_Select value={groupBy} options={[{title:'Agent', value:'a'},{title:'Infrastructure', value:'i'}]} onChange={e=>setGroupBy(e)} />})}</div>}
                  {addFunction && <div style={{margin:'5px'}}><MButton className='small m-0 input_height' onClick={addFunction} iconb={<FontAwesomeIcon icon={faAdd}/>} titre='Ajouter' /></div>}
                </div>
        </Col>
        
        <FullCalendar
                            locale={frLocale}
                            plugins={[resourceTimelinePlugin]}
                            initialView='resourceTimeline'
                            headerToolbar={{
                                left:'',
                                center: '',
                                right: 'title prev,next today resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
                              }}
                            height={150 + resources.length * 42}
                            aspectRatio={1.5}
                            editable= {true}
                            resourceAreaColumns={Columns()}
                            resourcesInitiallyExpanded={false}
                            resourceOrder="from"
                            resources={resources ?? []}
                            events={events}
                            eventClick={show_popup}
                            eventContent={Event}                        
                        />
    </> );
}
 
export default Calendar_Affectation;