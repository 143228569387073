import { MButton } from '../../components/Buttons/MButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { navigateto } from '../../Routes';
import Calendar_Element from '../../components/Calendar/Calendar_Element';

const Calendar_Info = () => {
    
    const history = useHistory();

    return ( <>
        <div>
            <Calendar_Element
                title="Calendrier"
                filterByState filterByInfra filterBySalle filterByAgent filterByClub
                history={history}  
            />
            
        </div>
      
    
    </> );
}
 
export default Calendar_Info;