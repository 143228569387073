import { useEffect, useState } from "react";
import { check_is_200, post } from "../../Helpers/xhr";
import { MButton } from "../../components/Buttons/MButton";
import { navigateto } from "../../Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCog, faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons";

const Mairie_Document_Info = ({ userToken, history }) => {

    const [list, setList] = useState([]);

    const getData = () => {
        post('mairie_doc_list', userToken )
        .then(xhr=>{
            if( check_is_200(xhr) && 'lst' in xhr.data)
            {
                setList(xhr.data.lst);
            }
        });
    }

    useEffect(getData, []);

    const show_doc = (item) => {
        window.open(item.file);
        //setBase64File(item);
    }

    return <>
        <>
            <div className="content_bloc p-3 border-black border-1">
                <div className="pageSubTitle mb-1">
                    <div className="flex justify-between">
                        <div>Document</div>
                        <MButton className="small" onClick={()=>navigateto({history, to:'mairie_docs'})} icone={<FontAwesomeIcon icon={faCog}/>}/>
                    </div>
                </div>
                {(list && list.length>0) && <>
                    <div>
                        {list.map(doc => <div className="content_bloc py-2 px-3 mb-2 border-1 border-gray-400" onClick={()=>show_doc(doc)}>
                                                    <div className="font-bold cursor-pointer">
                                                        <div>{doc.nom ?? ''}</div>
                                                    </div>
                                                </div>)}
                    </div>
                </>}
                
                
            </div>
        </>        
    </>
}
 
export default Mairie_Document_Info;