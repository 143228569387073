import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { MButton } from "../../components/Buttons/MButton";
import { navigateto } from "../../Routes";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { check_is_200, post } from "../../Helpers/xhr";
import toast from "react-hot-toast";
import Reclamation_List from "./list";

const Reclamation_List_Salle = () => {
    const { salle_key } = useParams();
    const history = useHistory();

    return <>
        <div className="header1">Les réclamations de la salle</div>
        <MButton onClick={()=>navigateto({history, to:'claim_salle_add', params:[salle_key]})} titre="Ajouter" />
        {salle_key && <Reclamation_List forSalle salleTkn={salle_key} />}
    </>  
}
 
export default Reclamation_List_Salle;