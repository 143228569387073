export function datetime_to_str(str, with_time = true)
{
    if(str=="") return "";

    let d = new Date(str);
    let date = [checkZero(d.getDate()), checkZero(d.getMonth()+1), checkZero(d.getFullYear())].join('/'); 
    if(with_time) date += " " + [checkZero(d.getHours()), checkZero(d.getMinutes())].join(':');
    return date;
}

export function currentDate_to_str(with_time = true)
{
    let d = new Date();
    let date = [checkZero(d.getDate()), checkZero(d.getMonth()+1), checkZero(d.getFullYear())].join('/'); 
    if(with_time) date += " " + [checkZero(d.getHours()), checkZero(d.getMinutes())].join(':');
    return date;
}

const checkZero = (data) => ""+( data< 10 ? "0" + data : data );
