import { Col, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { InputVal } from "../../components/form/inputVal";
import { useEffect, useState } from "react";
import { check_is_200, post } from "../../Helpers/xhr";
import toast from "react-hot-toast";
import Reclamation_Note_Add from "./note_add";
import { MButton } from "../../components/Buttons/MButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarAlt, faCalendarCheck, faCalendarDay, faCalendarDays, faCalendarPlus, faCalendarWeek, faClose, faCog, faPencil, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { goBack } from "../../Routes";
import ImagesModals from "../../Helpers/imagesModals";
import Event_Note_Add from "./note_add";

const Event_Notes = ({ the_key, hideborder, userToken, ajouter, history }) => {

    const [notes, setNotes] = useState([]);
    const [users, setUsers] = useState({});

    const [minId, setMinId] = useState('');
    const [maxId, setMaxId] = useState('');
    


    const getData = () => {
        post('event_notes', userToken, { tkn: the_key, max:maxId })
        .then(xhr => {
            if(check_is_200(xhr) && ('lst' in xhr.data))
            {
                setNotes([...notes, ...( xhr.data.lst ?? [] )]);
                setUsers({...users, ...( xhr.data.users ?? {} )});
                setMaxId(xhr.data.max ?? 0);
            }
            else toast.error(<b>Problème de chargement</b>);
        }).catch(function (error){
            
        });
    }

    useEffect(getData, []);


    const remove = (note)=>{
        confirmAlert({
            title: "Suppression de la note",
            message: <span>Êtes-vous certain vouloir supprimer la note de {(users[note.by].name ?? "")}</span>, // '+(users[note.by].name ?? "")+' !'},
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    const toastId = toast.loading('Traitement');
                    post('event_rmvnt', userToken, {tkn:the_key, note:note.tkn}).then(xhr => {
                        if(check_is_200(xhr) && 'state' in xhr.data)
                        {
                            if(xhr.data.state)
                            {
                                toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                                setNotes(notes.filter(e=>e.tkn != note.tkn));
                            }
                            else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                        else toast.error(<b>Problème de suppression</b>, { id: toastId, });                            
                    });
                }
              },
              { label: 'Non', }
            ]
          });
    }

    const Note = ({ note }) => {
        return <tr className={"timeline "+(note.etat ?'aboutstate':'')}>
                    <td className="timeline1">
                        <div className="content">
                            <div nowrap><b>{users[note.by].name ?? ""}</b></div>
                            <div>
                                {users[note.by].profile ?? ""}
                                <MButton className="small" onClick={()=>remove(note)} titre={<FontAwesomeIcon icon={faTimes}/>}/>
                            </div>
                        </div>
                    </td>
                    <td className="timeline2">
                        <FontAwesomeIcon icon={note.etat ? faCog : faPencil}/>
                    </td>
                    <td className="timeline3">
                        <div className="content">
                            <div><FontAwesomeIcon icon={faCalendarAlt}/> {note.date}</div>
                            <InputVal value={note.msg ?? ""} />
                            {note.imgs && <ImagesModals images={note.imgs}/>}
                        </div>
                    </td>
                </tr>
    }

    return ( <>
        <div className={"bg-white"+(hideborder ? '' : "rounded shadow-lg p-4 px-4 md:p-8 mb-6")}>
           <Row>
<Col>
           <table className="timelinetable"><tbody>
            {notes.map((note, k)=><Note key={k} note={note}/>)}
            </tbody></table> 

            {ajouter && <Event_Note_Add the_key={the_key} userToken={userToken} onAdded={getData} />}
</Col>
           </Row>
        
        </div>
    </> );
}
 
export default Event_Notes;