import { useEffect, useState } from "react";
import { Input_Text } from "../../components/form/inputText";
import { Form, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { MButton } from "../../components/Buttons/MButton";
import { goBack } from "../../Routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Input_Select } from "../../components/form/inputSelect";
import { check_is_200, packFiles, post, postFormData } from "../../Helpers/xhr";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import FilesUploader from "../../Helpers/fileUploader";

const Reclamation_Form = ({ forInfra, infraTkn, forSalle, salleTkn }) => {
    
    const { userToken } = useSelector((state) => state.auth);

    const [claim, setClaim] = useState({});
    const [validated, setValidated] = useState(false);
    const [infrastructure, setInfrastructure] = useState([]);
    const [joinedFiles, setJoinedFiles] = useState([]);
    const [salles, setSalles] = useState([]);
    const [types, setTypes] = useState([]);
    const [gravites, setGravites] = useState([]);

    const history = useHistory();

    
    const getItems = () => {
    
        post('claim_items', userToken)
        .then(xhr => {
            if(check_is_200(xhr))
            {
                setTypes(xhr.data.types ?? []);
                setGravites(xhr.data.gravites ?? []);
                setInfrastructure(xhr.data.salles ?? [])
            }
        }).catch(function (error){
            
        })
    }

    useEffect(getItems, []);

    useEffect(()=>{
        let infra = infrastructure.filter(e=>e.tkn == claim.infra);
        setSalles( infra.length > 0 ? infra[0].salles : [] );
        setClaim({...claim, salle:null})
    }, [claim.infra ?? ''])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {
            let packed = packFiles(joinedFiles);
            postFormData('claim_save', userToken, {...claim, imgs:Object.keys(packed.obj).join('|')}, packed.data )
            .then(xhr => {
                console.log('claim_save', claim, xhr.data);
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goBack({ history });
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
                    
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    return <div>
    <div className="pageHeader">
        <div className="pageTitle">Nouvelle réclamation</div>
    </div>
    <div style={{margin:'10px'}}>
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <Row>
                {Attribut({key:'Infrastructure', child:<Input_Select required options={infrastructure} onChange={e=>setClaim({...claim, infra : e})} fieldTitle="name" fieldValue="tkn" />})}
                {Attribut({key:'Salle', child:<Input_Select required options={salles} onChange={e=>setClaim({...claim, salle : e})} fieldTitle="name" fieldValue="tkn" />})}
                {Attribut({key:'Type de problème', child:<Input_Select required options={types} value={claim.type} onChange={e=>setClaim({...claim, type : e})} fieldTitle="name" fieldValue="tkn" />})}
                {Attribut({key:'Gravité', child:<Input_Select required options={gravites} value={claim.gravite} onChange={e=>setClaim({...claim, gravite : e})} fieldTitle="name" fieldValue="tkn" />})}
                {Attribut({key:"Date d'observation", child:<Input_Text type='datetime-local' value={claim.dateO} onChange={e=>setClaim({...claim, dateO : e})} />})}
            </Row>
            <Row>
                {Attribut({key:"Emplacement", child:<Input_Text type="text" required value={claim.lieu} onChange={e=>setClaim({...claim, lieu : e})} />})}
            </Row>
            <Row>
                {Attribut({key:"Description", child:<Input_Text type="text" as="textarea" value={claim.desc} onChange={e=>setClaim({...claim, desc : e})} />})}
            </Row>
            <Row>
                <FilesUploader images uploadedFiles={joinedFiles} setUploadedFiles={setJoinedFiles}/>
            </Row>
            
            <MButton type='submit' titre="Enregistrer" variant="primary"/>
            <MButton type='submit' titre="Annuler" onClick={()=>goBack({ history })} primary="danger"/>
        </Form>
    </div>
</div>;
}
 
export default Reclamation_Form;