import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MButton } from "../../components/Buttons/MButton";
import { navigateto } from "../../Routes";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { check_is_200, post } from "../../Helpers/xhr";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faInfo, faNoteSticky, faPencil } from "@fortawesome/free-solid-svg-icons";
import { MyModal } from "../../components/modals/myModal";
import Reclamation_Notes from "./notes";
import Reclamation_Etat from "./etat";
import { datetime_to_str } from "../../Helpers/dates";
import DataTable from "react-data-table-component";
import { filter_search_check_item, sort_function_by } from "../../Helpers/DataTableOpt";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { get_claim_state_name } from "../../Helpers/Variants";
import { selector } from "recoil";

const Reclamation_List = ({ forInfra, forSalle, infraTkn, salleTkn }) => {
    
    const { userToken } = useSelector((state) => state.auth);
    const history = useHistory();

    const [claims, setClaims] = useState([]);
    const [types, setTypes] = useState({});
    const [gravites, setGravites] = useState({});
    const [infrastructure, setInfrastructure] = useState([]);
    const [salles, setSalles] = useState([]);

    const [modalTitle, setModalTitle] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [selectedClaim, setSelectedClaim] = useState({});

    const getData = () => {
        let params = {'wexst':true, 'addb':true};
        if(forInfra) params = {...params, getby:'infra', infraTkn:infraTkn}
        if(forSalle) params = {...params, getby:'salle', salleTkn:salleTkn}

        post('claim_list', userToken, params)
        .then(xhr => {
            if(check_is_200(xhr) && ('lst' in xhr.data))
            {
                let lst = [];

                for(let e of xhr.data.lst)
                    lst.push({ ...e, 
                        gravite_name: get_gravite_name(e.gravite),
                        type_name: get_type_name(e.type),
                        etat_name: get_claim_state_name(e.etat),
                    })
                if(lst.length == xhr.data.lst.length)
                setClaims(lst);

                setTypes(xhr.data.types);
                setGravites(xhr.data.gravites);
                setInfrastructure(xhr.data.infras);
                setSalles(xhr.data.salles);
            }
            else toast.error(<b>Problème de chargement</b>);
        }).catch(function (error){
            
        });
    }

    useEffect(getData, []);


    const closeShowModal = () => setModalShow(!modalShow);
    const showMessage = (e) => {
      setModalTitle("Notes");
      setSelectedClaim(e);
      closeShowModal();
    }
    const modalContent = () => <Reclamation_Notes claim_key={selectedClaim.tkn} userToken={userToken} history={history} ajouter hideborder /> 
    
    const updateState = (ind, stt) => setClaims(claims.map((e, k) => {
        if(ind != k){
            return e
        }else{
            return {...e, etat:stt}
        }
    }));
    
    const [filterKey, setFilterKey] = useState('');
    
    const get_gravite_name = (itm) => itm in gravites ? gravites[itm].name : '';
    const get_gravite_color = (itm) => itm in gravites ? gravites[itm].color : '';
    const get_type_name = (itm) => itm in types ? types[itm].name : '';
    
    const columns = [
        { name:'Réf', sortable:true, width:'90px', selector:e=>e.ref ?? ''},
        { name:'Date', sortable:true, width:'140px', selector:e=>datetime_to_str(e.date)},
        { name:'Existance', sortable:true, width:'120px', selector:e=>e.existance},
        { name:'Créee', sortable:true, selector:e=>e.createBy},
        { name:'Gravité', sortable:true, width:'200px', 
            cell:e=><div className="tdIcons p-4 whitespace-nowrap" style={{background:e.graviteColor}}>{e.graviteName} </div>,
            sortFunction:sort_function_by('graviteName')
        },
        { name:'Type', sortable:true, width:'180px', selector:e=>e.typeName},
        { name:'Infrastructure', sortable:true, selector:e=>e.infra},
        { name:'Salle', sortable:true, classNames:'cold', selector:e=>e.salle},
        { name:'Etat', sortable:true, width:'210px', cell:(e, k)=><div className="tdIcons">
                                                                    <Reclamation_Etat spaceBetween claim={e} userToken={userToken} onChange={(stt)=>updateState(k, stt)} />
                                                                </div>,
                                                            sortFunction:sort_function_by('date')},
        { name:'Actions', width:'130px', cell:e=><div className="tdIcons">
                                                        <MButton className="small" iconb={<FontAwesomeIcon icon={faInfo}/>} onClick={()=>navigateto({history, to:'claim_info', params:e.tkn})}/>
                                                        <MButton className="small" iconb={<FontAwesomeIcon icon={faPencil}/>} onClick={()=>showMessage(e)}/>
                                                    </div>
        },
    ];
    

    return ( <>
    
        <div className="pageHeader">
            <div className="flex justify-between">
                <div className="flex flex-wrap">
                    <div className="pageTitle">Réclamation</div>
            
                    <div style={{margin:'5px 20px'}}>
                        <MButton className="small" onClick={()=>navigateto({history, to:'claim_add'})} iconb={<FontAwesomeIcon icon={faAdd}/>} titre="Ajouter" />
                    </div>
                </div>
                <div>
                    {Attribut({ isRow:true, contentSize:9, key:"Filtrer", child:<Input_Text value={filterKey} onChange={setFilterKey} />})}
                </div>
            </div>
        </div>
        <DataTable
            columns={columns}
            data={claims.filter(item => {
                let filter_key = filterKey.toLocaleLowerCase();
                return filter_search_check_item(filter_key, item, ['ref', 'Créee', 'existance', {k:'gravite', f:get_gravite_name}, {k:'type', f:get_type_name}, 'infra', 'salle', 'etat_name',]);
            })}
            pagination
            fixedHeader
            paginationPerPage={40}
            paginationRowsPerPageOptions={[40, 60, 100]}
            noDataComponent="Aucune infrastructure !"
            paginationComponentOptions={{
                                            rowsPerPageText: 'Infrastructure par page',
                                            rangeSeparatorText: 'sur',
                                            selectAllRowsItem: true,
                                            selectAllRowsItemText: 'Tous',
                                        }}
            />
        
      <MyModal
        title={modalTitle}
        size='lg'
        handleClose={()=>closeShowModal()}
        show={modalShow}
        dynamicContent={modalContent}
        contentHtml={true}
      />

    </> );
}
 
export default Reclamation_List;