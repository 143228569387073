import { useDispatch, useSelector } from "react-redux";
import { getItemRoute, navigateto } from "../Routes";
import { useHistory } from "react-router";
import { clear } from "../app/features/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faUser } from "@fortawesome/free-solid-svg-icons";
import { atom, useRecoilState } from "recoil";

const DefaultMenu = () => {
    
    const { userToken, userInfo } = useSelector((state) => state.auth);
    console.log(userInfo);

    const dispatch = useDispatch();
    const history = useHistory();
    
    const logout = () => {
        dispatch(clear());
    }

    const textState = atom({
        key: 'menuItemState', // unique ID (with respect to other atoms/selectors)
        default: '', // default value (aka initial value)
    });

    const MenuLink = ({ind, name, action=null}) => {
        const [indCurrent, setIndCurrent] = useRecoilState(textState);

        return <a className={ind.split('|').includes(indCurrent) ? "active" : ""} onClick={()=>{
            if(action != null){
                setIndCurrent(ind)
                action();
            }
        } }>{name}</a>
    }

    return <div className="defaultmenu">
            <div className="flex justify-between">
                <ul></ul>
                <ul >
                    <li><MenuLink ind='hom' name='Accueil'        action={() => navigateto({history, to:'/'})} /></li>
                    <li><MenuLink ind='cld' name='Calendrier'     action={() => navigateto({history, to:'calendar_list'})} /></li>
                    <li><MenuLink ind='aff' name='Affectation'    action={() => navigateto({history, to:'affectation_list'})} /></li>
                    <li><MenuLink ind='inf' name='Infrastructure' action={() => navigateto({history, to:'infra_list'})} /></li>
                    <li><MenuLink ind='req' name='Réservation'        action={() => navigateto({history, to:'reservation_waiting'})} /></li>
                    <li><MenuLink ind='rcl' name='Réclamation'    action={() => navigateto({history, to:'claim_list'})} /></li>
                    <li>
                        <MenuLink ind='adm|agn' name='Comptes' />
                        <ul>
                            <li><MenuLink ind='adm' name='Administrateur' action={() => navigateto({history, to:'admin_list'})} /></li>
                            <li><MenuLink ind='agn' name='Agent' action={() => navigateto({history, to:'agent_list'})} /></li>
                        </ul>
                    </li>
                    <li><MenuLink ind='eve' name='Evénement'    action={() => navigateto({history, to:'event_list'})} /></li>
                    <li><MenuLink ind='asc' name='Association'    action={() => navigateto({history, to:'association_list'})} /></li>
                </ul>
                <ul>
                    <li><MenuLink ind='prf' name={<><FontAwesomeIcon icon={faUser}/> Profil</>} action={() => navigateto({history, to:'my_profile_info'})}/></li>
                    <li style={{width:'50px'}}><a onClick={() => logout()}><FontAwesomeIcon icon={faSignOut}/></a></li>
                </ul>
            </div>
            
            </div>;
    }
 
export default DefaultMenu;