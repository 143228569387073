import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { navigateto } from "../../Routes";
import { MButton } from "../../components/Buttons/MButton";
import Affectation_Table from "./table";

const Affectation_Infra = () => {
    const history = useHistory();
    const { infra_key } = useParams();

    return ( <>
        <Affectation_Table  
            title={"Affectations d'infrastructure"} 
            addFunction={()=>navigateto({history, to:'affectation_infra_add', params:[infra_key]})} 
            forInfra 
            infra_token={infra_key} />
    </> );
}
 
export default Affectation_Infra;