import { useEffect, useState } from "react";
import { navigateto } from "../../Routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MButton } from "../../components/Buttons/MButton";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../Helpers/DataTableOpt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faInfo, faPencil, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { check_is_200, post } from "../../Helpers/xhr";
import { datetime_to_str } from "../../Helpers/dates";
import Event_Notes from "./notes";
import { MyModal } from "../../components/modals/myModal";
import { InputVal } from "../../components/form/inputVal";
import { confirmAlert } from "react-confirm-alert";

const Event_List = () => {

    const { userToken } = useSelector(state=>state.auth);
    const history = useHistory();
    const [list, setList] = useState([]);
    const [filterKey, setFilterKey] = useState('');

    const getData = () => {
        const toastId = toast.loading('Chargement');
          
        post('event_list', userToken)
        .then(xhr => {
            if(check_is_200(xhr) && ('lst' in xhr.data))
            {
              setList(xhr.data.lst);
              toast.success(<b>Terminé</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            
        });
    }
  
    const [selectedEvent, setSelectedEvent] = useState(null);
    const showNotes = (e) => setSelectedEvent(e);
    const modalContent = () => { return selectedEvent != null 
    ? <>
        <Event_Notes the_key={selectedEvent.tkn} userToken={userToken} history={history} ajouter hideborder /> 
    </>
    : <></> }
    

    useEffect(()=>{
        getData();
    }, []);

    const goDelete = (e) => confirmAlert({
        title: "Suppression de l'événement",
        message: "Êtes-vous certain de vouloir supprimer l'événement "+e.title+" !",
        buttons: [
          {
            label: 'Oui',
            onClick: () => {
                
                const toastId = toast.loading('Traitement');
  
                post('event_remv', userToken, {tkn:e.tkn}).then(xhr => {
                    if(check_is_200(xhr) && 'state' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setList(list.filter(i=>i.tkn!=e.tkn))
                        }
                        else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else toast.error(<b>Problème de suppression</b>, { id: toastId, });                            
                });
            }
          },
          { label: 'Non', }
        ]
    });


    const columns = [
        { name:'Titre', sortable:true, selector:r=>r.title??''},
        { name:'Organisateur', sortable:true, selector:r=>r.org??''},
        { name:'Date de début', sortable:true, selector:r=>datetime_to_str(r.dd??'', false)},
        { name:'Date de fin', sortable:true, selector:r=>datetime_to_str(r.df??'', false)},
        { name:'Heure de début', sortable:true, selector:r=>r.hd??''},
        { name:'Heure de fin', sortable:true, selector:r=>r.hf??''},
        { name:'Infrastructure', sortable:true, selector:r=>r.infraNom??''},
        { name:'Salle', sortable:true, selector:r=>r.salleNom??''},
        { name:'Actions', width:'250px', cell:e=><div className="tdIcons">
                                                    <MButton className="small" onClick={()=>navigateto({history, to:'event_info', params:[e.tkn]})} iconb={<FontAwesomeIcon icon={faInfo} />} />
                                                    <MButton className="small" onClick={()=>navigateto({history, to:'event_update', params:[e.tkn]})} iconb={<FontAwesomeIcon icon={faCog} />} />
                                                    <MButton className="small" onClick={()=>showNotes(e)} iconb={<FontAwesomeIcon icon={faPencil}/>} />
                                                    <MButton className="small" onClick={()=>goDelete(e)} iconb={<FontAwesomeIcon icon={faTimes}/>} />
                                                  </div>
        },
    ];

    
    return ( <>
        <div className="pageHeader">
            <div className="flex justify-between">
                <div className="flex flex-wrap">
                    <div className="pageTitle">Evènement</div>
                    
                    <div style={{margin:'5px 20px'}}>
                        <MButton 
                            className="small"
                            titre="Ajouter" 
                            iconb={<FontAwesomeIcon icon={faPlus} />}
                            onClick={()=>navigateto({ history, to:'event_add'})}
                        />
                    </div>
                </div>
                <div>
                {Attribut({ isRow:true, contentSize:9, key:"Filtrer", child:<Input_Text value={filterKey} onChange={setFilterKey} />})}
                </div>
            </div>
        </div>
        
        
        <DataTable
            columns={columns}
            data={list.filter(item => {
                let filter_key = filterKey.toLocaleLowerCase();
                return filter_search_check_item(filter_key, item, ['titre']);
            })}
            pagination
            fixedHeader
            paginationPerPage={40}
            paginationRowsPerPageOptions={[40, 60, 100]}
            noDataComponent="Aucune événement !"
            paginationComponentOptions={{
                                            rowsPerPageText: 'événement par page',
                                            rangeSeparatorText: 'sur',
                                            selectAllRowsItem: true,
                                            selectAllRowsItemText: 'Tous',
                                        }}
            />


        <MyModal
                title={selectedEvent != null ? "Notes d'événement: "+selectedEvent.title : ""}
                size='lg'
                handleClose={()=>setSelectedEvent(null)}
                show={selectedEvent != null}
                dynamicContent={modalContent}
                contentHtml={true}
            />
    </> );
}
 
export default Event_List;