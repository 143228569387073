import { Button, Form, Modal } from "react-bootstrap";

export function MyModal({
    title,
    show=false,
    handleClose=()=>show = !show,
    size, // xl
    content,
    contentHtml=false,
    dynamicContent,
    buttons = [],
    isForm=false,
    validated=false,
    handleSubmit
}){
    let myElement = <>
                    {title && <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>}
                    {(content != null || dynamicContent!=null) && <Modal.Body>
                        {!contentHtml ? content : <div dangerouslySetInnerHTML={{__html: content}}/>}
                        {dynamicContent!=null && dynamicContent()}
                    </Modal.Body>}
                    {buttons.length>0 && <Modal.Footer>
                        {buttons.map((e, k)=>
                                <Button type={e.type ?? "button"} variant={e.variant ?? ""} onClick={e.click} className={e.className ?? ""} >
                                    {e.title}
                                </Button>
                            )}
                    </Modal.Footer>}</>;

    return <>
        <Modal size={size} show={show} onHide={handleClose}>
            {isForm ? <Form noValidate validated={validated} onSubmit={handleSubmit}>{myElement}</Form> : myElement}
        </Modal>
    </>
}