import { Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { InputVal } from "../../components/form/inputVal";
import { MButton } from "../../components/Buttons/MButton";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { goBack, navigateto } from "../../Routes";
import { check_is_200, post } from "../../Helpers/xhr";
import toast from "react-hot-toast";
import Reclamation_Notes from "./notes";
import ImagesModals from "../../Helpers/imagesModals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCog, faMessage } from "@fortawesome/free-solid-svg-icons";
import Reclamation_Etat from "./etat";
import { datetime_to_str } from "../../Helpers/dates";
import { MyModal } from "../../components/modals/myModal";
import DataTable from "react-data-table-component";

const Reclamation_Info = () => {

    const { userToken } = useSelector((state) => state.auth);
    const { claim_key } = useParams();

    const [claim, setClaim] = useState({});
    
    const history = useHistory();

    
    const getData = () => {
        post('claim_info', userToken, { tkn: claim_key })
        .then(xhr => {
            if(check_is_200(xhr) && ('claim' in xhr.data))
            {
                setClaim(xhr.data.claim ?? {});
            }
            else toast.error(<b>Problème de chargement</b>);
        }).catch(function (error){
            
        });
    }

    useEffect(getData, []);


    const [modalTitle, setModalTitle] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');
    
    const closeShowModal = () => setModalShow(!modalShow);
    const showMessage = (title, msg) => {
      setModalTitle(title);
      setModalContent(msg);
      closeShowModal();
    }

    const showComment = (e) => showMessage("Commentaire d'accès", e.commentaire);
    const showJustif = (e) => showMessage("Détail", e.justif);

    const columns = [
        { name:'Date de réclamation', selector:r=>datetime_to_str(r.date ?? "")},
        { name:'Par', width:'200px', selector:r=>r.par??''},
        { name:'Profil', width:'200px', selector:r=>r.profile??''},
        { name:"Date d'observation", width:'200px', selector:r=>r.obs??''},
        { name:'Etat', width:'200px', selector:c=><Reclamation_Etat spaceBetween claim={c} userToken={userToken} onChange={(e)=>setClaim({...c, etat:e})}/>},
        { name:'Infrastructure', width:'200px', selector:r=>r.infra??''},
        { name:'Salle', width:'200px', selector:r=>r.salle??''},
        { name:'Emplacement', width:'200px', selector:r=>r.lieu??''},
        { name:'Gravité', width:'200px', selector:r=>r.gravite??''},
        { name:'Type de problème', width:'200px', selector:r=>r.type??''},
    ];

    return ( <>
    <div className="pageHeader">
        <div className="pageTitle">Fiche réclamation</div>
    </div>
    <div>
        <MButton type='button'  titre="Retour" onClick={()=>goBack({ history })} className="small" iconb={<FontAwesomeIcon icon={faArrowLeft}/>}/>
        
        
        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <Row className="mb-4">
                <DataTable
                    columns={columns}
                    data={[claim]}
                />
            </Row>
            {/** 
            <Row>
                {Attribut({key:"Date de réclamation", child:<InputVal type='datetime-local' value={datetime_to_str(claim.date ?? "")} />})}
                {Attribut({key:"Par", child:<InputVal type='datetime-local' value={claim.par ?? ""} />})}
                {Attribut({key:"Profil", child:<InputVal type='datetime-local' value={claim.profile ?? ""} />})}
                {Attribut({key:"Date d'observation", child:<InputVal type='datetime-local' value={claim.obs ?? ""} />})}
                {Attribut({key:"Etat", child:<Reclamation_Etat spaceBetween claim={claim} userToken={userToken} onChange={(e)=>setClaim({...claim, etat:e})}/>})}
            </Row>
            <Row>
                {Attribut({key:'Infrastructure', child:<InputVal value={claim.infra}  />})}
                {Attribut({key:'Salle', child:<InputVal value={claim.salle} />})}
                {Attribut({key:"Emplacement", child:<InputVal type="text" value={claim.lieu} />})}
                {Attribut({key:'Gravité', child:<InputVal value={claim.gravite} />})}
                {Attribut({key:'Type de problème', child:<InputVal value={claim.type} />})}
            </Row>
            <Row>
            </Row>
            */}

            {claim.detail != "" && <Row>
                {Attribut({key:"Description", child:<InputVal type="text" as="textarea" value={claim.detail} />})}
            </Row>}
            {(claim.images && claim.images.length>0) && <Row>
                {Attribut({key:"Images", child:<ImagesModals images={claim.images}/>})}
                    
            </Row>}
        </div>
        <br/>
        <div className="pageSubTitle">Notes</div>
        <Reclamation_Notes claim_key={claim_key} userToken={userToken} history={history} ajouter />

    </div>
    
    <MyModal
        title={modalTitle}
        handleClose={()=>closeShowModal()}
        show={modalShow}
        content={modalContent}
        contentHtml={true}
      />
    
    </> );
}
 
export default Reclamation_Info;