import axios from "axios";

function returnAxiosInstance(){
    return axios.create({});
}

export const getUrlCtr = () => "https://mairie.monclub.online/api/alphard/get.php";

function getParams(object)
{
    let result = [];
    for (const [key, value] of Object.entries(object)) {
        result.push(`${key}=${value}`);
    }
    return result.join('&');
}

export function get(action='', params = {}, config={}){
    const inst = returnAxiosInstance();
    let url = getUrlCtr();
    let args = getParams({...params, q:action});
    if(args) url += '?'+ args;
    return inst.get(url, config);
}

export function post(action='', token, params = {}, config = {}, build=false)
{
    if(token) params = {...params, auth:token};
    
    if(build) console.log({...params, q:action});
    
    return axios.post(getUrlCtr(), processParams({...params, q:action}));
}

export function postFormData(action='', token, params = {}, formData, build=false)
{
    if(token) params = {...params, auth:token};
    
    if(build) console.log({...params, q:action});

    params = { ...params, q:action};
    
    for(const item of Object.keys(params)) formData.append(item, typeof params[item] == "object" ? JSON.stringify(params[item]) : params[item])

    return axios.post(getUrlCtr(), formData, {headers: {
        'content-type': 'multipart/form-data'
    }});
}

export function postUrl(url, requestData, config = {"Content-Type": "application/json"}){
    const inst = returnAxiosInstance();
    return inst.post(url, (requestData), config);
}

export function upload(action, requestData, onUploadProgress = ()=>{}){
    const inst = returnAxiosInstance();
    let url = getUrlCtr(action);
    return inst.post(url, requestData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, 
        withCredentials: false,
        onUploadProgress
    });
}
export function downloadFile(ctr, action, params, file_name='fichier', file_ext='pdf', onDownload = (e)=>{}, onDownloadProg = (e)=>{}) {
    // // console.log(params)
    return post(ctr, action, processParams(params), {
        responseType: 'blob',
        onDownloadProgress(progressEvent) {
            let progressShow = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            onDownloadProg(progressShow);
        },
    })
    .then(function (response){
        onDownload(response);
        downloadBlob(response.data, file_name+'.'+file_ext);
    });
}

export function popup_show_file(file)
{
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,`;

    window.open(getUrlCtr('file', 'show')+'?file='+file, 'MonclubOnline', params);
}

export function check_is_200(xhr){
    return (('status' in xhr) && (xhr.status===200));
}

export function check_is_201(xhr){
    return (('status' in xhr) && (xhr.status===201));
}

export function progressMessage(nbr, msg = "Envoi", msg_en_att = 'Traitement') {
    return <b>{nbr < 100 ? msg+' '+ (nbr > 0 ? nbr+' %' : '...') : msg_en_att+'...'} </b>;
}

export function progressDownloadMessage(nbr) {
    return progressMessage(nbr, 'Téléchargement', 'Traitement')
}

export function processParams(obj) {
    let params = new URLSearchParams();
    //let params = new FormData();
    for (let key in obj) 
        processParamItem(params, key, obj[key]);

        showParams(params);
    return params;
}

function processParamItem(params, key, val) {
    if(val !== null){
        if (Array.isArray(val) || (typeof val === 'object')) {
            
            if((Array.isArray(val) && val.length == 0) || (typeof val === 'object' && Object.keys(val).length == 0))
                processParamItem(params, key, '');
            else
            for (let skey in val) {
                processParamItem(params, key+'['+skey+']', val[skey]);
            }
        }else{
            params.append(key, val);
        }
    }
}

export function showParams(params){
    params.forEach((val, ind)=>{
        console.log(ind, ':', val);
    });
}

export function downloadBlob(blob, filename){
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
}

export function httpErrorMessage(xhr, defaultMessage = 'Problème !'){
    return xhr.data['']['errors'][0]['errorMessage']??'Problème !';
}

export const packFiles = (files) => {
    const data = new FormData();
    let filesObj = {};
    [...files].forEach((file, i) => {
        data.append(`file-${i}`, file, file.name)
        filesObj[`file-${i}`] = file;
    })
    return {'data':data, 'obj':filesObj}
}

export function check_server()
{
    console.log('check_communication start');
    get('AATesta', 'hey')
    .then(xhr => {
        console.info(xhr.data);
    }).catch(function (error){
        console.error('check_communication', error);
    }).finally(() => {
        console.log('check_communication end');
    });
}

export function getExtension(mime)
{
    let types = {
        'text/html' : 'html htm shtml',
        'text/css' : 'css',
        'text/xml' : 'xml',
        'image/gif' : 'gif',
        'image/jpeg' : 'jpeg jpg',
        'application/x-javascript' : 'js',
        'application/atom+xml' : 'atom',
        'application/rss+xml' : 'rss',
    
        'text/mathml' : 'mml',
        'text/plain' : 'txt',
        'text/vnd.sun.j2me.app-descriptor' : 'jad',
        'text/vnd.wap.wml' : 'wml',
        'text/x-component' : 'htc',
    
        'image/png' : 'png',
        'image/tiff' : 'tif tiff',
        'image/vnd.wap.wbmp' : 'wbmp',
        'image/x-icon' : 'ico',
        'image/x-jng' : 'jng',
        'image/x-ms-bmp' : 'bmp',
        'image/svg+xml' : 'svg',
        'image/webp' : 'webp',
    
        'application/java-archive' : 'jar war ear',
        'application/mac-binhex40' : 'hqx',
        'application/msword' : 'docx',
        'application/pdf' : 'pdf',
        'application/postscript' : 'ps eps ai',
        'application/rtf' : 'rtf',
        'application/vnd.ms-excel' : 'xlsx',
        'application/vnd.ms-powerpoint' : 'pptx',
        'application/vnd.wap.wmlc' : 'wmlc',
        'application/x-cocoa' : 'cco',
        'application/x-java-archive-diff' : 'jardiff',
        'application/x-java-jnlp-file' : 'jnlp',
        'application/x-makeself' : 'run',
        'application/x-perl' : 'pl pm',
        'application/x-pilot' : 'prc pdb',
        'application/x-rar-compressed' : 'rar',
        'application/x-redhat-package-manager' : 'rpm',
        'application/x-sea' : 'sea',
        'application/x-shockwave-flash' : 'swf',
        'application/x-stuffit' : 'sit',
        'application/x-tcl' : 'tcl tk',
        'application/x-x509-ca-cert' : 'der pem crt',
        'application/x-xpinstall' : 'xpi',
        'application/xhtml+xml' : 'xhtml',
        'application/zip' : 'zip',
    
        // 'application/octet-stream' : 'bin exe dll',
        // 'application/octet-stream' : 'deb',
        // 'application/octet-stream' : 'dmg',
        // 'application/octet-stream' : 'eot',
        // 'application/octet-stream' : 'iso img',
        // 'application/octet-stream' : 'msi msp msm',
    
        'audio/midi' : 'mid midi kar',
        'audio/mpeg' : 'mp3',
        'audio/ogg' : 'ogg',
        'audio/x-realaudio' : 'ra',
    
        'video/3gpp' : '3gpp 3gp',
        'video/mpeg' : 'mpeg mpg',
        'video/quicktime' : 'mov',
        'video/x-flv' : 'flv',
        'video/x-mng' : 'mng',
        'video/x-ms-asf' : 'asf',
        'video/x-ms-wmv' : 'wmv',
        'video/x-msvideo' : 'avi',
        'video/mp4' : 'm4v mp4',
    }

    if(mime in types) return types[mime];
    return 'pdf';
}