import { useEffect, useState } from "react";
import { check_is_200, post } from "../../Helpers/xhr";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Form, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { MButton } from "../../components/Buttons/MButton";
import { getItemRoute, navigateto } from "../../Routes";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Input_Text } from "../../components/form/inputText";
import { Input_Check } from "../../components/form/inputCheck";
import { InputVal } from "../../components/form/inputVal";
import { faArrowLeft, faCalendar, faCalendarAlt, faCog, faHeadset, faMessage, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { MyModal } from "../../components/modals/myModal";


const Salle_Info = () => {

    const { userToken } = useSelector(state=>state.auth);

    const { salle_key } = useParams();
    const [salle, setSalle] = useState({});

    const getData = () => {
        const toastId = toast.loading('Chargement');
          
        post('salle_info', userToken, {salle:salle_key, 'widtheqp':true})
        .then(xhr => {
            //console.log('salle_info', {salle:salle_key, 'widtheqp':true}, xhr.data);
            if(check_is_200(xhr) && ('salle' in xhr.data))
            {
              setSalle(xhr.data.salle);
              toast.success(<b>Terminé</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            
        });
    }

    useEffect(()=>{
        if(salle_key) getData();
    }, [])

    
    const [modalTitle, setModalTitle] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');
    
    const closeShowModal = () => setModalShow(!modalShow);
    const showMessage = (title, msg) => {
      setModalTitle(title);
      setModalContent(msg);
      closeShowModal();
    }

    const showJustif = (e) => showMessage("Détail", e.justif);


    const history = useHistory();
    const goBack = () => history.goBack();

    const godelete = () => {
        confirmAlert({
            title: "Suppression de la salle",
            message: 'Êtes-vous certain de cette action !',
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    
                    const toastId = toast.loading('Traitement');

                    post('salle_remv', userToken, {salle:salle_key}).then(xhr => {
                        if(check_is_200(xhr) && 'state' in xhr.data)
                        {
                            if(xhr.data.state)
                            {
                                toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                                goBack();
                            }
                            else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                        else toast.error(<b>Problème de suppression</b>, { id: toastId, });                            
                    });
                }
              },
              { label: 'Non', }
            ]
          });
    }


    const columns = [
        { name:'Nom', width:'300px', selector:r=>r.name??''},
        { name:'Equipements', cell:r=><div>
            {(salle.equips ?? []).map((e, k)=><MButton className="small"
                                            onClick={()=>{}} 
                                            titre={e}    
                                            />)}
        </div>},
        { name:'Superficie m²', width:'120px', selector:r=>r.superficie??''},
        { name:'Accessibilité', width:'130px', selector:e=><td className="tdIcons">
                                    {e.access ? 'Oui' : 'Non'}
                                    {e.access ? '' : <MButton className='small' iconb={<FontAwesomeIcon icon={faMessage}/>} onClick={()=>showJustif(e)} show={e.justif} />}
                                  </td>},
        { name:'Actions', width:'420px', cell:e=><div className="tdIcons">
                    <MButton className="small" iconb={<FontAwesomeIcon icon={faCog}/>} onClick={()=>navigateto({history, to:'salle_update', params:[salle_key ?? '']})} />
                    <MButton className="small" iconb={<FontAwesomeIcon icon={faCalendarAlt}/>} onClick={()=>navigateto({history, to:'salle_calendar', params:[salle_key ?? '']})} titre="Calendrier"/>
                    <MButton className="small" iconb={<FontAwesomeIcon icon={faHeadset}/>} onClick={()=>navigateto({history, to:'claim_salle_list', params:[salle_key ?? '']})} titre="Réclamations"/>
                    <MButton className="small" iconb={<FontAwesomeIcon icon={faTimes}/>} onClick={()=>godelete()} />
                </div>
        },
    ];

    return ( <div>
        <div className="pageHeader">
        <h2 className="pageTitle">Salle</h2>
        <MButton className="small" iconb={<FontAwesomeIcon icon={faArrowLeft}/>} onClick={()=>goBack()} titre="Retour"/>
        </div>
        
        <div>
            <Row className="mb-4">
                <DataTable
                    columns={columns}
                    data={[salle]}
                />
            </Row>
            <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                <Row>
                    {Attribut({key:"Description", child:<InputVal value={salle.commentaire} />})}
                </Row>
            </div>    
            
            <MyModal
                title={modalTitle}
                handleClose={()=>closeShowModal()}
                show={modalShow}
                content={modalContent}
                contentHtml={true}
            />    
        </div>
    </div> );
}
 
export default Salle_Info;