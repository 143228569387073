import { Table } from "react-bootstrap";
import { useHistory } from "react-router";
import { MButton } from "../../components/Buttons/MButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faPlus, faTimes, faUsers } from "@fortawesome/free-solid-svg-icons";
import { getItemRoute, navigateto } from "../../Routes";
import { Col, Container, Row } from "react-bootstrap";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { check_is_200, deleta, get, post } from "../../Helpers/xhr";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { MyModal } from "../../components/modals/myModal";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../Helpers/DataTableOpt";
import RequiredItem from "../../Helpers/RequiredItem";
import { InputVal } from "../../components/form/inputVal";

const Agent_List = () => {

  const { userToken } = useSelector((state) => state.auth);
  const history = useHistory();
  const [users, setUsers] = useState([]);

  const [contentConfModal, setContentConfModal] = useState('');
  const [new_psw, setNew_psw] = useState('');
  const [cnew_psw, setCnew_psw] = useState('');
  const [showConfModal, setShowConfModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  //const account = useSelector((state) => state.account);
  const getData = () => {
      const toastId = toast.loading('Chargement');
        
      post('agent_list', userToken)
      .then(xhr => {
            console.log('agent_list', userToken, xhr.data);
          if(check_is_200(xhr) && ('lst' in xhr.data))
          {
            setUsers(xhr.data.lst);
            toast.success(<b>Terminé</b>, { id: toastId, });
          }
          else toast.error(<b>Problème de chargement</b>, { id: toastId, });
      }).catch(function (error){
          
      });
  }

  useEffect(()=>{
      getData();
  }, []);
    
    const navigate = (to) => {
        return history.push({
            pathname: getItemRoute(to),
            state:{back:true},
        });
    };

    const closeShowModal = () => setShowConfModal(false);
    const updatePasse = (item) => {
        setSelectedUser(item);
        setShowConfModal(true);
    }

    const getForm = () => {
      return <>
                <Row>
                  {Attribut({isRow:true, key:"Agent", contentClassName:"py-2 text-right font-bold", child:<InputVal value={selectedUser.nom+' '+selectedUser.prenom}/>})}
                </Row>
                <Row>
                    {Attribut({key:'Nouveau mot de passe', child:<Input_Text required={true} type='password' value={new_psw} onChange={e=>setNew_psw(e)} />})}
                </Row>
                <Row>
                    {Attribut({key:'Confirmer le nouveau mot de passe', child:<Input_Text required={true} type='password' value={cnew_psw} onChange={e=>setCnew_psw(e)} />})}
                </Row>                
                <RequiredItem condition={new_psw==cnew_psw} message="Les mots de passe que vous avez entrés ne correspondent pas" />
            </>;
    }

    const handleUpdatePassword = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {
            setShowConfModal(false);
            post('agent_umdp', userToken, {usr:selectedUser.tkn, mdp:new_psw} ).then(xhr => {
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        setNew_psw('');
                        setCnew_psw('');
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
                    
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    const goDelete = (item) => {
        confirmAlert({
            title: "Suppression d'agent",
            message: 'Êtes-vous certain de cette action !',
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    const toastId = toast.loading('Traitement');
                    post('agent_remv', userToken, {usr:item.tkn}).then(xhr => {
                        if(check_is_200(xhr) && 'state' in xhr.data)
                        {
                            if(xhr.data.state)
                            {
                                toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                                setUsers(users.filter(e=>e.tkn!=item.tkn));
                            }
                            else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                        else toast.error(<b>Problème de suppression</b>, { id: toastId, });                            
                    });
                }
              },
              { label: 'Non', }
            ]
          });
    }

    const [filterKey, setFilterKey] = useState('');

    const columns = [
        { name:'Nom', sortable:true, selector:r=>r.nom??''},
        { name:'Prénom', sortable:true, selector:r=>r.prenom??''},
        { name:'Tel', sortable:true, width:'130px', selector:r=>r.tel??''},
        { name:'Email', sortable:true, width:'300px', selector:r=>r.email??''},
        { name:'Active', sortable:true, width:'100px', selector:r=>r.active ? 'Oui' : 'Non'},
        { name:'Actions', width:'500px', cell:e=><div className="tdIcons">
                                                    <MButton className="small" onClick={()=>navigateto({history, to:'agent_info', params:[e.tkn]})} iconb={<FontAwesomeIcon icon={faInfo} />} />
                                                    <MButton className="small" onClick={()=>navigateto({history, to:'agent_update', params:[e.tkn]})} iconb={<FontAwesomeIcon icon={faCog} />} />
                                                    <MButton className="small" onClick={()=>goDelete(e)} iconb={<FontAwesomeIcon icon={faTimes}/>} />
                                                    <MButton className="small" variant="warning" onClick={()=>updatePasse(e)} titre="Changer mot de passe" icone={<FontAwesomeIcon icon={faCog} />}/>
                                                    <MButton className="small" variant="warning" onClick={()=>navigateto({history, to:'affectation_agent', params:[e.tkn]})} titre="Affectations" icone={<FontAwesomeIcon icon={faUsers} />}/>
                                                  </div>
        },
    ];

    return ( <div>
      <div className="pageHeader">
      <div className="flex justify-between">
        <div className="flex flex-wrap">
            <div className="pageTitle">Agent</div>
            
            <div style={{margin:'5px 20px'}}>
              <MButton 
                className="small"
                  titre="Ajouter" 
                  iconb={<FontAwesomeIcon icon={faPlus} />}
                  onClick={()=>navigate('agent_add')}
                />
            </div>
        </div>
        <div>
          {Attribut({ isRow:true, contentSize:9, key:"Filtrer", child:<Input_Text value={filterKey} onChange={setFilterKey} />})}
        </div>
      </div>
      </div>
      
      <DataTable
            columns={columns}
            data={users.filter(item => {
                let filter_key = filterKey.toLocaleLowerCase();
                return filter_search_check_item(filter_key, item, ['nom', 'prenom', 'email']);
            })}
            
            pagination
            fixedHeader
            paginationPerPage={40}
            paginationRowsPerPageOptions={[40, 60, 100]}
            noDataComponent="Aucune agent !"
            paginationComponentOptions={{
                                            rowsPerPageText: 'agent par page',
                                            rangeSeparatorText: 'sur',
                                            selectAllRowsItem: true,
                                            selectAllRowsItemText: 'Tous',
                                        }}
            />
      <MyModal
        title="Changement du mot de passe"
        handleClose={()=>closeShowModal()}
        show={showConfModal}
        dynamicContent={()=>getForm()}
        isForm={true}
        handleSubmit={handleUpdatePassword}
        validated={validated}
        buttons={[
            {title:'Enregistrer', variant:'primary', type:'submit'},
            {title:'Annuler', click:()=>closeShowModal()},
        ]}
      />
    </div> );
}
 
export default Agent_List;