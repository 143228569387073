import { combineReducers, configureStore, createStore } from '@reduxjs/toolkit'
import authReducer from './features/auth/authSlice'
import storageSession from 'redux-persist/lib/storage/session'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk';
// import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage:storageSession,
}


const reducer = combineReducers({auth:authReducer});

const persistedReducer = persistReducer(persistConfig, reducer)


/*
export const store = configureStore({
  reducer: persistedReducer,
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false,}),
  //middleware: [thunk, logger]
}) */

export const store = createStore(persistedReducer);
export const persistor = persistStore(store)

/*
// store.js
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Choose your storage engine
import authSlice from './features/auth/authSlice';

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, {auth:authSlice});
export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
*/