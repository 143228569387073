import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { check_is_200, post } from "../../Helpers/xhr";
import { useSelector } from "react-redux";
import { MButton } from "../../components/Buttons/MButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { datetime_to_str } from "../../Helpers/dates";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../Helpers/DataTableOpt";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { get_existance_request_color } from "../../Helpers/Variants";

const Reservation_En_Attentes = () => {
    
    const { userToken } = useSelector((state) => state.auth);
    const [request_list, setRequest_list] = useState([]);

    
    const getData = () => {
        const toastId = toast.loading('Chargement');
          
        post('reserv_ena', userToken, {'wexst':true})
        .then(xhr => {
            if(check_is_200(xhr) && ('lst_even' in xhr.data) && ('lst_seance' in xhr.data))
            {
                setRequest_list((xhr.data.lst_even).concat(xhr.data.lst_seance));
                toast.success(<b>Terminé</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            
        });
    }

    useEffect(()=>getData(), []);

    const decision = (decision, reservation)=>{
        const toastId = toast.loading('Traitement');
        post('reserv_grp_decision', userToken, {type:reservation.type, decision, res:reservation.tkn})
        .then(xhr => {
            if(check_is_200(xhr) && ('state' in xhr.data))
            {
                if(xhr.data.state)
                {
                    toast.success(<b>{xhr.data.msg ?? "OK"}</b>, { id: toastId, });
                    setRequest_list(request_list.filter(e=>!(e.tkn==reservation.tkn && e.type==reservation.type)));
                }
                else toast.error(<b>{xhr.data.msg ?? "NOT OK"}</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            toast.error(<b>Problème de chargement</b>, { id: toastId, });
        });
    }
    
    
    const [filterKey, setFilterKey] = useState('');

    const columns = [
        { name:'Réf.', sortable:true, width:'140px', selector:r=>r.ref},
        { name:'Date de la demande', sortable:true, width:'180px', selector:r=>datetime_to_str(r.le)},
        { name:'Existance', sortable:true, width:'120px', cell:r=><div className="tdIcons p-1" style={{background:get_existance_request_color(r.existanceDays ?? '')}}>{r.existance}</div>},
        { name:'Club', sortable:true, selector:r=>r.club},
        { name:'Infrastructure', sortable:true, selector:r=>r.infra},
        { name:'Salle', sortable:true, selector:r=>r.salle},
        { name:'Date début', sortable:true, width:'130px', selector:r=>datetime_to_str(r.dateDebut, false)},
        { name:'Date fin', sortable:true, width:'130px', selector:r=>datetime_to_str(r.dateFin, false)},
        { name:'Heure début', sortable:true, width:'130px', selector:r=>r.heureDebut},
        { name:'Heure fin', sortable:true, width:'130px', selector:r=>r.heureFin},
        { name:'Actions', width:'140px', cell:r=><div className="tdIcons">
                                                    <MButton className="small" iconb={<FontAwesomeIcon icon={faCheck}/>} onClick={()=>decision('a', r)}/>
                                                    <MButton className="small" iconb={<FontAwesomeIcon icon={faTimes}/>} onClick={()=>decision('r', r)}/>
                                                  </div>
        },
    ];

    return ( <>
    
        <div className="pageHeader">
            <div className="flex justify-between">
                <div className="pageTitle">Réservation</div>
                <div>
                    {Attribut({ isRow:true, contentSize:9, key:"Filtrer", child:<Input_Text value={filterKey} onChange={setFilterKey} />})}
                </div>
            </div>
        </div>
        <DataTable
            columns={columns}
            data={request_list.filter(item => {
                let filter_key = filterKey.toLocaleLowerCase();
                return filter_search_check_item(filter_key, item, ['ref', 'club', 'infra', 'salle']);
            })}
            
            pagination
            fixedHeader
            paginationPerPage={40}
            paginationRowsPerPageOptions={[40, 60, 100]}
            noDataComponent="Aucune demande !"
            paginationComponentOptions={{
                                            rowsPerPageText: 'Demandes par page',
                                            rangeSeparatorText: 'sur',
                                            selectAllRowsItem: true,
                                            selectAllRowsItemText: 'Tous',
                                        }}
            />
    </> );

    
}

export default Reservation_En_Attentes;