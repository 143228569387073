import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { navigateto } from "../../Routes";
import { MButton } from "../../components/Buttons/MButton";
import Affectation_Table from "./table";

const Affectation_Agent = () => {

    const history = useHistory();
    const { agent_key } = useParams();

    return ( <>
        <Affectation_Table 
            title={"Affectations d'agent"} 
            addFunction={()=>navigateto({history, to:'affectation_agent_add', params:[agent_key]})} 
            forAgent 
            agent_token={agent_key} 
        />
    </> );
}
 
export default Affectation_Agent;