import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { navigateto } from "../../Routes";
import { MButton } from "../../components/Buttons/MButton";
import { check_is_200, post } from "../../Helpers/xhr";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Col, Row, Table } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import Calendar_Affectation from "../../components/Calendar/Calendar_Affectation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { datetime_to_str } from "../../Helpers/dates";
import DataTable from "react-data-table-component";
import { filter_search_check_item, sort_function_by } from "../../Helpers/DataTableOpt";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { Input_Select } from "../../components/form/inputSelect";

const Affectation_Table = ({ title, addFunction, forAgent, forInfra, agent_token, infra_token, isGlobal }) => {
    
    const { userToken } = useSelector ((state) => state.auth);
    const history = useHistory();

    const [isLoaded, setIsLoaded] = useState(false);
    const [affectations, setAffectations] = useState([]);
    const [filtredAffectations, setFiltredAffectations] = useState([]);
    const [agent_list, setAgent_list] = useState([]);
    const [infra_list, setInfra_list] = useState([]);
    const [resources_list, Resources_list] = useState([]);
    const [ressouces_by_id, setRessouces_by_id] = useState({});

    const getData = () => {
        setIsLoaded(false);

        let params = {'wplg':true, 'wstt':true};
        if( forAgent ) params = {...params, agent:agent_token}
        if( forInfra ) params = {...params, infra:infra_token}

        post('afct_list', userToken, params)
        .then(xhr => {
            console.log('afct_list', userToken, params, xhr.data);
            if(check_is_200(xhr) && ('lst' in xhr.data))
            {
                setAffectations(xhr.data.lst);
                setAgent_list(xhr.data.agents ?? []);
                setInfra_list(xhr.data.infras ?? []);
                Resources_list(xhr.data.ressources ?? []);
                setRessouces_by_id((xhr.data.ressources ?? []).reduce((acc,curr)=> (acc[curr.id]=curr,acc),{}))
            }
            else toast.error(<b>Problème de chargement</b>);
        }).catch(function (error){
            
        }).finally(()=>setIsLoaded(true));
    }

    useEffect(getData, []);
    
    const goDelete = (item) => {
        confirmAlert({
            title: "Suppression d'affectation",
            message: `Êtes-vous certain de retirer l'affectation de l'agent ${item.agentNom} à l'infrastructure ${item.infraNom} du ${item.debut} au ${item.fin} !`,
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    const toastId = toast.loading('Traitement');
                    post('afct_remove', userToken, {afc:item.tkn}).then(xhr => {
                        console.log(xhr.data);
                        if(check_is_200(xhr) && 'state' in xhr.data)
                        {
                            if(xhr.data.state)
                            {
                                toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                                setAffectations(affectations.filter(e=>e.tkn!=item.tkn));
                            }
                            else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                        else toast.error(<b>Problème de suppression</b>, { id: toastId, });                            
                    });
                }
              },
              { label: 'Non', }
            ]
          });
    }


    const [filterKey, setFilterKey] = useState('');
    const [filterType, setFilterType] = useState('cur');

    const columns = [
        { name:'Agent', sortable:true, width:'200px', selector:r=>r.agentNom},
        { name:'Etat', sortable:true, width:'200px', cell:r=><div style={{backgroundColor:r.etatColor, padding:'8px'}}>{r.etat}</div>},
        { name:'Début', sortable:true, width:'200px', selector:r=>datetime_to_str(r.debut), sortFunction:sort_function_by('debut')},
        { name:'Fin', sortable:true, width:'200px', selector:r=>datetime_to_str(r.fin), sortFunction:sort_function_by('fin')},
        { name:'Infrastructure', sortable:true, selector:r=>r.infraNom},
        { name:'Actions', width:'100px', cell:r=><MButton className="small" onClick={()=>goDelete(r)} iconb={<FontAwesomeIcon icon={faTimes} />}/>},
    ].filter((e)=>!((forAgent && e.name=='Agent') || (forInfra && e.name=='Infrastructure')));

    const typesList = [
        {title:'Toutes', value:'all'}, 
        {title:'Programées', value:'ftr'},
        {title:'En cours', value:'cur'}, 
        {title:'Passées', value:'pas'}, 
    ];

    const check_Type = (item) => {
        switch(filterType)
        {
            case 'pas': return (item.is_previous ?? false);
            case 'cur': return (item.is_current ?? false);
            case 'ftr': return (item.is_next ?? false);
            default: return true;
        }
    }

    return ( <>
        <div style={{marginBottom:'10px'}}>        
            <Calendar_Affectation
                title={title}
                addFunction={addFunction}
                forAgent={forAgent}
                forInfra={forInfra}
                activeGroup={isGlobal}
                resources={resources_list}
                events={[...affectations.map((e, k)=>{ 
                    let name = '';
                    if(e.infagent in ressouces_by_id)
                        name = ressouces_by_id[e.infagent].agent;
                    return e.plage.map((hr, kh) => {
                        return {
                            id:k+'_'+kh,
                            name:name,
                            day:hr.day, 
                            color:'#57CF8A', 
                            resourceId:e.infagent,
                            time:datetime_to_str(hr.date, false)+ (hr.start ? ' de '+hr.start + ' au '+hr.end : ''),
                            start:new Date(hr.date+' '+(hr.start ?? '06:00')), 
                            end:new Date(hr.date+' '+(hr.end ?? '23:00'))
                        }
                    })
                })].flat()}
            />
        </div>
        
        <Row className="my-2">
            <Col md={{ span: 4, offset: 8 }}>
                <div className="flex justify-between">
                {Attribut({ isRow:true, size:6, contentSize:9, key:"Filtrer", child:<Input_Text value={filterKey} onChange={setFilterKey} />})}
                {Attribut({ isRow:true, size:6, contentSize:10, key:"Type", child:<Input_Select value={filterType} onChange={setFilterType} options={typesList} />})}
                </div>
            </Col>
        </Row>
        <DataTable
            columns={columns}
            data={affectations.filter(item => {
                let filter_key = filterKey.toLocaleLowerCase();
                return filter_search_check_item(filter_key, item, ['agentNom', 'infraNom']) && check_Type(item);
            })}
            
            pagination
            fixedHeader
            paginationPerPage={40}
            paginationRowsPerPageOptions={[40, 60, 100]}
            noDataComponent="Aucune affectation !"
            paginationComponentOptions={{
                                            rowsPerPageText: 'Affectation par page',
                                            rangeSeparatorText: 'sur',
                                            selectAllRowsItem: true,
                                            selectAllRowsItemText: 'Tous',
                                        }}
            />
    </> );
}
 
export default Affectation_Table;