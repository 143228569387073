import { Form, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { useEffect, useState } from "react";
import { Input_Select } from "../../components/form/inputSelect";
import { MButton } from "../../components/Buttons/MButton";
import toast from "react-hot-toast";
import { check_is_200, get, post } from "../../Helpers/xhr";
import { useHistory, useParams } from "react-router-dom";
import { all_cities, all_counties } from "../../Helpers/locations";
import { Input_Check } from "../../components/form/inputCheck";
import { useSelector } from "react-redux";
import { InputInt } from "../../components/form/inputInt";

const Infrastructure_Form = () => {

    const { userToken } = useSelector((state) => state.auth);
    const { infra_key } = useParams();
    const [isUpdate, setIsUpdate] = useState(false);


    const [infra, setInfra] = useState({});

    useEffect(()=>{
        if(infra_key)
        {
            setIsUpdate(true);
            getData(infra_key);
        }
    }, [infra_key]);

    const getData = () => {
        const toastId = toast.loading('Chargement');
          
        post('infra_info', userToken, {infra:infra_key})
        .then(xhr => {
            console.log(xhr.data);
            if(check_is_200(xhr) && ('infra' in xhr.data))
            {
              setInfra(xhr.data.infra);
              toast.success(<b>Terminé</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){ });
    }

    const [validated, setValidated] = useState(false);
    
    const history = useHistory();
    const goBack = () => history.goBack();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {
            post('infra_save', userToken, infra).then(xhr => {
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goBack();
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
                    
            });
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    return <div>
    <div className="pageHeader">
        <div className="pageTitle">{isUpdate ? 'Modification' : 'Nouvelle'} infrastructure</div>
    </div>
    <div style={{margin:'10px'}}>
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <Row>
                {Attribut({key:'Nom', child:<Input_Text required={true} value={infra.name} onChange={e=>setInfra({...infra, name : e})} />})}
                {Attribut({key:'Adresse', size:6, child:<Input_Text required={true} value={infra.address} onChange={e=>setInfra({...infra, address : e})} />})}
                {Attribut({key:'Code postal', child:<Input_Text required={true} value={infra.cp} onChange={e=>setInfra({...infra, cp : e})} />})}
                {Attribut({key:'Ville', child:<Input_Text required={true} value={infra.city} onChange={e=>setInfra({...infra, city : e})} />})}
                {Attribut({key:'Telephone', child:<Input_Text required={true} value={infra.tel} onChange={e=>setInfra({...infra, tel : e})} />})}
            </Row>
            <Row>
                {Attribut({key:"Commentaire d'accès", child:<Input_Text required={false} type="text" as="textarea" value={infra.commentaire} onChange={e=>setInfra({...infra, commentaire : e})} />})}
            </Row>
            <Row>
                {Attribut({key:'Accessibilité', size:2, child:<Input_Check label="Oui" value={infra.access} required={false} onChange={e=>setInfra({...infra, access : e})} />})}
                {!(infra.access ?? true) && Attribut({key:"Détail", child:<Input_Text required={false} type="text" as="textarea" value={infra.justif} onChange={e=>setInfra({...infra, justif : e})} />})}
            </Row>
            <MButton type='submit' titre="Enregistrer" variant="primary"/>
            <MButton type='submit' titre="Annuler" onClick={()=>goBack()} className="btn-danger"/>
        </Form>
    </div>
</div>;
}
 
export default Infrastructure_Form;