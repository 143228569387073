import { Form, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { useEffect, useState } from "react";
import { Input_Select } from "../../components/form/inputSelect";
import { MButton } from "../../components/Buttons/MButton";
import toast from "react-hot-toast";
import { check_is_200, get, post } from "../../Helpers/xhr";
import { useHistory, useParams } from "react-router-dom";
import { all_cities, all_counties } from "../../Helpers/locations";
import { Input_Check } from "../../components/form/inputCheck";
import { useSelector } from "react-redux";
import { InputInt } from "../../components/form/inputInt";
import { InputVal } from "../../components/form/inputVal";
import { navigateto } from "../../Routes";
import { faCog, faInfo, faTimes, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import RequiredItem from "../../Helpers/RequiredItem";
import { MyModal } from "../../components/modals/myModal";

const Agent_Info = () => {

    const { userToken } = useSelector((state) => state.auth);
    const { agent_key } = useParams();
    
    const [isUpdate, setIsUpdate] = useState(false);


    const [user, setUser] = useState({});
    
    useEffect(()=>{
        if(agent_key)
        {
            setIsUpdate(true);
            getData(agent_key);
        }
    }, [agent_key]);


    const getData = () => {
        const toastId = toast.loading('Chargement');
          
        post('agent_info', userToken, {usr:agent_key, upd:true})
        .then(xhr => {
            console.log('agent_info', xhr.data);
            if(check_is_200(xhr) && ('user' in xhr.data))
            {
              setUser(xhr.data.user);
              toast.success(<b>Terminé</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            
        });
    }

    const [validated, setValidated] = useState(false);
    
    const history = useHistory();
    const goBack = () => history.goBack();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {
            let params = {...user};
            if('tkn' in user)
                params = {...user, usr:user.tkn}

            post('agent_save', userToken, params ).then(xhr => {
                console.log('agent_save', params, xhr.data);
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        goBack();
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
                    
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    const [new_psw, setNew_psw] = useState('');
    const [cnew_psw, setCnew_psw] = useState('');
    const [showConfModal, setShowConfModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});


    const closeShowModal = () => setShowConfModal(false);
    const updatePasse = (item) => {
        setSelectedUser(item);
        setShowConfModal(true);
    }

    const getForm = () => {
      return <>
                <Row>
                  {Attribut({isRow:true, key:"Agent", contentClassName:"py-2 text-right font-bold", child:<InputVal value={selectedUser.nom+' '+selectedUser.prenom}/>})}
                </Row>
                <Row>
                    {Attribut({key:'Nouveau mot de passe', child:<Input_Text required={true} type='password' value={new_psw} onChange={e=>setNew_psw(e)} />})}
                </Row>
                <Row>
                    {Attribut({key:'Confirmer le nouveau mot de passe', child:<Input_Text required={true} type='password' value={cnew_psw} onChange={e=>setCnew_psw(e)} />})}
                </Row>                
                <RequiredItem condition={new_psw==cnew_psw} message="Les mots de passe que vous avez entrés ne correspondent pas" />
            </>;
    }

    const handleUpdatePassword = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Enregistrement');
        
        if(form.checkValidity() == true)
        {
            setShowConfModal(false);
            post('agent_umdp', userToken, {usr:selectedUser.tkn, mdp:new_psw} ).then(xhr => {
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        setNew_psw('');
                        setCnew_psw('');
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
                    
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    const goDelete = (item) => {
        confirmAlert({
            title: "Suppression d'agent",
            message: 'Êtes-vous certain de cette action !',
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    const toastId = toast.loading('Traitement');
                    post('agent_remv', userToken, {usr:item.tkn}).then(xhr => {
                        if(check_is_200(xhr) && 'state' in xhr.data)
                        {
                            if(xhr.data.state)
                            {
                                toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                                goBack();
                            }
                            else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                        }
                        else toast.error(<b>Problème de suppression</b>, { id: toastId, });                            
                    });
                }
              },
              { label: 'Non', }
            ]
          });
    }
    
    const columns = [
        { name:'Nom', selector:r=>r.nom??''},
        { name:'Prénom', selector:r=>r.prenom??''},
        { name:'Tel', width:'130px', selector:r=>r.tel??''},
        { name:'Email', width:'300px', selector:r=>r.email??''},
        { name:'Active', width:'100px', selector:r=>r.active ? 'Oui' : 'Non'},
        { name:'Actions', width:'500px', cell:e=><div className="tdIcons">
                                                    <MButton className="small" onClick={()=>navigateto({history, to:'agent_update', params:[e.tkn]})} iconb={<FontAwesomeIcon icon={faCog} />} />
                                                    <MButton className="small" onClick={()=>goDelete(e)} iconb={<FontAwesomeIcon icon={faTimes}/>} />
                                                    <MButton className="small" variant="warning" onClick={()=>updatePasse(e)} titre="Changer mot de passe" icone={<FontAwesomeIcon icon={faCog} />}/>
                                                    <MButton className="small" variant="warning" onClick={()=>navigateto({history, to:'affectation_agent', params:[agent_key]})} titre="Affectations" icone={<FontAwesomeIcon icon={faUsers} />}/>
                                                  </div>
        },
    ];


    return <div>
    <div className="pageHeader">
        <div className="pageTitle">Agent</div>
    </div>
    <DataTable
        columns={columns}
        data={[user]}
        />


    <MyModal
        title="Changement du mot de passe"
        handleClose={()=>closeShowModal()}
        show={showConfModal}
        dynamicContent={()=>getForm()}
        isForm={true}
        handleSubmit={handleUpdatePassword}
        validated={validated}
        buttons={[
            {title:'Enregistrer', variant:'primary', type:'submit'},
            {title:'Annuler', click:()=>closeShowModal()},
        ]}
      />
</div>;
}
 
export default Agent_Info;