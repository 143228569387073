import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isConnected: false,
  isResp: false,
  userInfo: {}, // for user object
  userToken: null, // for storing the JWT
  mairieInfo: {},
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    save:(state, { payload }) => {
        state.isConnected = true;
        state.isResp = payload.isResp ?? false;
        state.userInfo = payload.info ?? {};   
        state.userToken = payload.info.auth ?? '';  
        state.mairieInfo = payload.mairie ?? {};  
    },
    updateIsResp:(state, { payload }) => {
        state.isResp = payload;  
    },
    updateInfo:(state, { payload }) => {
      state.userInfo = payload;  
    },
    updateMairie:(state, { payload }) => {
        state.mairieInfo = payload;  
    },
    updateToken:(state, { payload }) => {
        state.userToken = payload;  
    },
    clear:(state) => {
        state.isConnected = false;
        state.userInfo = {}
        state.userToken = {}
    },
  },
})


export const { save, updateIsResp, updateMairie, updateInfo, updateToken, clear } = authSlice.actions

export default authSlice.reducer