import Affectation_Form from "./form";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const Affectation_Add_For_Agent = () => {
    
    const { agent_key } = useParams();
    
    return ( <>
    
        <div className="pageHeader">
            <h1 className="pageTitle">Nouvelle affectation</h1>
        </div>
        <Affectation_Form forAgent agent_token={agent_key} />
    </> );
}
 
export default Affectation_Add_For_Agent;