import { faAlignRight, faArrowAltCircleRight, faArrowLeft, faArrowRight, faDiagramNext, faLongArrowAltRight, faRightLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const ImagesModals = ({ images }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentImg, setCurrentImg] = useState(null);


    const openModal = (indice) =>
    {
        setCurrentImg(indice);
        setShowModal(true);
    }

    const previouse_btn = () => get_btn(false)
    const next_btn = () => get_btn(true)

    const get_btn = (isNext) => {
        
        let isActive = isNext ? (currentImg < images.length - 1) : (currentImg > 0);

        return <div className="nav" onClick={()=>!isActive ? null : setCurrentImg(isNext ? currentImg + 1 : currentImg - 1) } >
                    <FontAwesomeIcon 
                        icon={isNext ? faArrowRight : faArrowLeft} 
                        size="6x" 
                        color={isActive ? "black" : "transparent"} 
                    />
                </div>
    }

    return ( <>
        <div className="joined-files-list">
            {(images).map((e, k)=><div onClick={()=>openModal(k)} className="imagePicker" style={{backgroundImage:`url('${e}')`}}></div>)}
        </div>
        <Modal
            contentClassName="pick_navigation"
            show={showModal}
            onHide={()=>setShowModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
                {next_btn()}
                <img src={images[currentImg]}/>
                {previouse_btn()}
        </Modal>
                
    </> );
}
 
export default ImagesModals;