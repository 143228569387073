import Admin_Form from "./pages/admin/form";
import Admin_Info from "./pages/admin/info";
import Admin_List from "./pages/admin/list";
import Agent_Form from "./pages/agent/form";
import Agent_List from "./pages/agent/list";
import LoginPage from "./pages/authentification/login";
import ResetPasswordPage from "./pages/authentification/reset";
import Calendar_Info from "./pages/reservation/calendar";
import Infrastructure_Form from "./pages/infrastructure/form";
import Infrastructure_Info from "./pages/infrastructure/info";
import Infrastructure_List from "./pages/infrastructure/list";
import Salle_Form from "./pages/salle/form";
import Salle_Info from "./pages/salle/info";
import Reservation_En_Attentes from "./pages/reservation/enattentes";
import Infrastructure_Calendar from "./pages/infrastructure/calendar";
import Salle_Calendar from "./pages/salle/calendar";
import Agent_Info from "./pages/agent/info";
import Affectation_Agent from "./pages/affectation/agent";
import Affectation_Salle from "./pages/affectation/salle";
import Affectation_Infra from "./pages/affectation/infra";
import Affectation_Form from "./pages/affectation/form";
import Affectation_List from "./pages/affectation/list";
import Affectation_Add_For_Agent from "./pages/affectation/add_for_agent";
import Affectation_Add from "./pages/affectation/add";
import Affectation_Add_For_Infrastructure from "./pages/affectation/add_for_infra";
import Reclamation_List from "./pages/reclamation/list";
import Reclamation_Form from "./pages/reclamation/form";
import Reclamation_Info from "./pages/reclamation/info";
import Reclamation_List_Infra from "./pages/reclamation/infra";
import Reclamation_List_Salle from "./pages/reclamation/salle";
import Reclamation_Salle_Form from "./pages/reclamation/form_salle";
import Reclamation_Infra_Form from "./pages/reclamation/form_infra";
import Infrastructure_Hours from "./pages/infrastructure/hours";
import My_Profile_Info from "./pages/myProfile/info";
import My_Profile_Form from "./pages/myProfile/form";
import Mairie_Form from "./pages/Mairie/form";
import Mairie_Services from "./pages/Mairie/services";
import Event_List from "./pages/Event/list";
import Event_Info from "./pages/Event/info";
import Event_Form from "./pages/Event/form";
import Association_List from "./pages/association/list";
import Mairie_Documents from "./pages/Mairie_Document/list";
import Mairie_Club_Documents from "./pages/association/documents";
import association_info from "./pages/association/info";
import Association_info from "./pages/association/info";
import Mairie_Club_Documents_Categories from "./pages/association/document_cat";

export function controllers_list(){
    return {
        contr_infra: "/infrastructure",
        contr_salle: "/salle",
        contr_admin: "/administrateur",
        contr_agent: "/agent",
        contr_association: "/association",
        contr_event: "/evenement",
        contr_affectation: "/affectation",
        contr_requests: "/demandes",
        contr_claims: "/reclamations",
        contr_login: "/connexion",
        contr_myprofil: "/profil",
        contr_mairie: "/mairie",
    }
}


export function profiles_list(){
    return {
        responsable: "prs",
        user: "usr"
    }
}

let getList_connect = (mainCtr, profiles) => [
    {
        id:'connect_login',
        layout: mainCtr,
        path: "/login",  
        component: LoginPage,
    },{
        id:'connect_reset',
        layout: mainCtr,
        path: "/recuperation",
        component: ResetPasswordPage,
    }
];

let getList_infrastructure = (mainCtr, profiles) => [
    {
        id:'infra_list',
        layout: mainCtr,
        path: "/list",  
        component: Infrastructure_List,
    },{
        id:'infra_info',
        layout: mainCtr,
        path: "/info",  
        params: "/:infra_key",
        component: Infrastructure_Info,
    },{
        id:'infra_add',
        layout: mainCtr,
        path: "/add",  
        component: Infrastructure_Form,
    },{
        id:'infra_update',
        layout: mainCtr,
        path: "/update",
        params: "/:infra_key",  
        component: Infrastructure_Form,
    },{
        id:'infra_calendar',
        layout: mainCtr,
        path: "/calendar",  
        params: "/:infra_key", 
        component: Infrastructure_Calendar,
    },{
        id:'infra_hours',
        layout: mainCtr,
        path: "/horaires",  
        params: "/:infra_key", 
        component: Infrastructure_Hours,
    }
];

let getList_salle = (mainCtr, profiles) => [
    {
        id:'salle_info',
        layout: mainCtr,
        path: "/info",  
        params: "/:salle_key",
        component: Salle_Info,
    },{
        id:'salle_add',
        layout: mainCtr,
        path: "/add",  
        params: "/:infra_key",
        component: Salle_Form,
    },{
        id:'salle_update',
        layout: mainCtr,
        path: "/update",
        params: "/:salle_key",  
        component: Salle_Form,
    },{
        id:'salle_calendar',
        layout: mainCtr,
        path: "/calendar",
        params: "/:salle_key",  
        component: Salle_Calendar,
    }
];

let getList_administration = (mainCtr, profiles) => [
    {
        id:'admin_list',
        layout: mainCtr,
        path: "/list",
        component: Admin_List,
    },{
        id:'admin_info',
        layout: mainCtr,
        path: "/info",  
        params: "/:admin_key",
        component: Admin_Info,
    },{
        id:'admin_add',
        layout: mainCtr,
        path: "/add",
        component: Admin_Form,
    },{
        id:'admin_update',
        layout: mainCtr,
        path: "/update",
        params: "/:admin_key",  
        component: Admin_Form,
    }
];

let getList_agents = (mainCtr, profiles) => [
    {
        id:'agent_list',
        layout: mainCtr,
        path: "/list",
        component: Agent_List,
    },{
        id:'agent_info',
        layout: mainCtr,
        path: "/info",  
        params: "/:agent_key",
        component: Agent_Info,
    },{
        id:'agent_add',
        layout: mainCtr,
        path: "/add",
        component: Agent_Form,
    },{
        id:'agent_update',
        layout: mainCtr,
        path: "/update",
        params: "/:agent_key",  
        component: Agent_Form,
    }
];

let getList_affectations = (mainCtr, profiles) => [
    {
        id:'affectation_list',
        layout: mainCtr,
        path: "/liste",
        component: Affectation_List,
    },{
        id:'affectation_agent',
        layout: mainCtr,
        path: "/agent/liste",
        params: "/:agent_key",
        component: Affectation_Agent,
    },{
        id:'affectation_salle',
        layout: mainCtr,
        path: "/salle/liste",  
        params: "/:salle_key",
        component: Affectation_Salle,
    },{
        id:'affectation_infra',
        layout: mainCtr,
        path: "/infrastructure/liste",  
        params: "/:infra_key",
        component: Affectation_Infra,
    },{
        id:'affectation_add',
        layout: mainCtr,
        path: "/ajouter",
        component: Affectation_Add,
    },{
        id:'affectation_agent_add',
        layout: mainCtr,
        path: "/agent/ajouter",
        params: "/:agent_key",
        component: Affectation_Add_For_Agent,
    },{
        id:'affectation_infra_add',
        layout: mainCtr,
        path: "/infrastructure/ajouter",
        params: "/:infra_key",
        component: Affectation_Add_For_Infrastructure,
    }
];

let getList_associations = (mainCtr, profiles) => [
    {
        id:'association_list',
        layout: mainCtr,
        path: "/liste",
        component: Association_List,
    },{
        id:'association_docs',
        layout: mainCtr,
        path: "/documents",
        component: Mairie_Club_Documents,
    },{
        id:'association_docs_cats',
        layout: mainCtr,
        path: "/categories",
        component: Mairie_Club_Documents_Categories,
    },{
        id:'association_info',
        layout: mainCtr,
        path: "/info",  
        params: "/:association_key",
        component: Association_info,
    }
];



let getList_mairie = (mainCtr, profiles) => [
    {
        id:'mairie_form',
        layout: mainCtr,
        path: "/form",
        component: Mairie_Form,
    },{
        id:'mairie_services',
        layout: mainCtr,
        path: "/services",
        component: Mairie_Services,
    },{
        id:'mairie_docs',
        layout: mainCtr,
        path: "/documents",
        component: Mairie_Documents,
    },
];

let getList_events = (mainCtr, profiles) => [
    {
        id:'event_list',
        layout: mainCtr,
        path: "/list",
        component: Event_List,
    },{
        id:'event_info',
        layout: mainCtr,
        path: "/info",  
        params: "/:event_key",
        component: Event_Info,
    },{
        id:'event_add',
        layout: mainCtr,
        path: "/add",
        component: Event_Form,
    },{
        id:'event_update',
        layout: mainCtr,
        path: "/update",
        params: "/:event_key",  
        component: Event_Form,
    }
];

let getList_requests = (mainCtr, profiles) => [
    {
        id:'calendar_list',
        layout: mainCtr,
        path: "/calendrier",
        component: Calendar_Info,
    },
    {
        id:'reservation_waiting',
        layout: mainCtr,
        path: "/enattente",
        component: Reservation_En_Attentes,
    }
    /*
    {
        id:'category_list',
        layout: mainCtr,
        path: "/list",  
        component: Category_List,
    },{
        id:'category_form',
        layout: mainCtr,
        path: "/add",  
        component: Category_Form,
    },{
        id:'category_form_update',
        layout: mainCtr,
        path: "/update",
        params: "/:category_key",  
        component: Category_Form,
    }
    */
];

let getList_claims = (mainCtr, profiles) => [
    {
        id:'claim_list',
        layout: mainCtr,
        path: "/list",  
        component: Reclamation_List,
    },{
        id:'claim_infra_list',
        layout: mainCtr,
        path: "/infrastructure/list",
        params: "/:infra_key",  
        component: Reclamation_List_Infra,
    },{
        id:'claim_salle_list',
        layout: mainCtr,
        path: "/salle/list",  
        params: "/:salle_key",
        component: Reclamation_List_Salle,
    },{
        id:'claim_info',
        layout: mainCtr,
        path: "/fiche",
        params: "/:claim_key",  
        component: Reclamation_Info,
    },{
        id:'claim_add',
        layout: mainCtr,
        path: "/add",  
        component: Reclamation_Form,
    },{
        id:'claim_salle_add',
        layout: mainCtr,
        path: "/salle/add", 
        params: "/:salle_key", 
        component: Reclamation_Salle_Form,
    },{
        id:'claim_infra_add',
        layout: mainCtr,
        path: "/infrastructure/add",
        params: "/:infra_key",  
        component: Reclamation_Infra_Form,
    }
];

let getList_my_profil = (mainCtr, profiles) => [
    {
        id:'my_profile_info',
        layout: mainCtr,
        path: "/info",  
        component: My_Profile_Info,
    },{
        id:'my_profile_form',
        layout: mainCtr,
        path: "/update",  
        component: My_Profile_Form,
    },{
        id:'my_profile_mairie',
        layout: mainCtr,
        path: "/mairie",  
        component: My_Profile_Info,
    },
];

function getList()
{
    const controllers = controllers_list();
    const profiles = profiles_list();

    return   [
        ...getList_connect(controllers.contr_login, profiles),
        ...getList_infrastructure(controllers.contr_infra, profiles),
        ...getList_salle(controllers.contr_salle, profiles),
        ...getList_administration(controllers.contr_admin, profiles),
        ...getList_agents(controllers.contr_agent, profiles),
        ...getList_events(controllers.contr_event, profiles),
        ...getList_affectations(controllers.contr_affectation, profiles),
        ...getList_associations(controllers.contr_association, profiles),
        ...getList_requests(controllers.contr_requests, profiles),
        ...getList_claims(controllers.contr_claims, profiles),
        ...getList_my_profil(controllers.contr_myprofil, profiles),
        ...getList_mairie(controllers.contr_mairie, profiles),
    ];
}


export const allRoutes = (connected) => {
    const controllers = controllers_list();
    const profiles = profiles_list();

    if(connected === true)
        return [
            ...getList_infrastructure(controllers.contr_infra, profiles),
            ...getList_salle(controllers.contr_salle, profiles),
            ...getList_administration(controllers.contr_admin, profiles),
            ...getList_agents(controllers.contr_agent, profiles),
            ...getList_events(controllers.contr_event, profiles),
            ...getList_affectations(controllers.contr_affectation, profiles),
            ...getList_associations(controllers.contr_association, profiles),
            ...getList_requests(controllers.contr_requests, profiles),
            ...getList_claims(controllers.contr_claims, profiles),
            ...getList_my_profil(controllers.contr_myprofil, profiles),
            ...getList_mairie(controllers.contr_mairie, profiles),
        ];
    else 
    return [
            ...getList_connect(controllers.contr_login, profiles),
        ];
}

export function getItem(indice) {
    let list = getList().filter((e) => e.id===indice);
    if(list.length > 0) return list[0];
    else return false;
}

export function getItemsByLayout(layout) {
    return getList().filter((e) => e.layout===layout);
}

export function getItemsByLayouts(layouts) {
    return getList().filter((e) => layouts.includes(e.layout));
}

export function getItemRoute(indice, params) {
    let item = getItem(indice);
    // // console.log('ITEM', item);
    if(item) return item.layout + item.path +(params ? '/'+(Array.isArray(params) ? params.join('/') : params) : '');
    else return '/';
}

export function navigateto({history, to, params})
{
    return history.push({
        pathname: getItemRoute(to, params),
        state:{back:true},
    });
}


export const goBack = ({ history }) => history.goBack();