import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Calendar_Element from "../../components/Calendar/Calendar_Element";

const Infrastructure_Calendar = () => {
    
    const { infra_key } = useParams();
    
    return ( <>
        <div>
            <Calendar_Element title={"Réservations de l'infrastructure"} filterBySalle={true} filterByState={true} infraTkn={infra_key} />
            
        </div>
    </> );
}
 
export default Infrastructure_Calendar;