import { Form } from "react-bootstrap";

const compare = (v1, v2) => {

    if(typeof v1 === typeof v2 && typeof v2 === 'string')
        return v1.toLowerCase().trim() === v2.toLowerCase().trim();
    return v1 == v2;
}

const theDefaultValue = (val) => typeof val === 'string' ? val.toLowerCase().trim() : val;

export function Input_Select({
    options=[], // {title, value}
    value,
    required=false,
    onChange,
    disabled=false,
    fieldValue='value',
    fieldTitle='title',
    subfieldTitle = [],
    default_opt_title = 'Choisissiez',
    default_opt_value = '',
    keepDefault = false,
}){
    let is_selected = false;
    let optionsList = !(typeof options === 'object' || typeof options === 'array') ? <></> : options.map((e, k) => {
                    if(typeof e === 'object'){
                        let etitle = e[fieldTitle]; 
                        if(typeof etitle === 'object'){
                            let etitlecp = {...etitle};
                            for(let et of subfieldTitle) etitlecp = etitlecp[et];
                            etitle = etitlecp;
                        }

                        let is_choosen = compare(value, e[fieldValue])
                        is_selected = is_selected || is_choosen
                        return <option key={k} value={e[fieldValue]} selected={is_choosen} >{etitle}</option>
                    }
                    if(typeof e === 'string'){
                        is_selected = is_selected || compare(value, e)
                        return <option key={k} value={e} selected={compare(value, e)}>{e}</option>
                    }
                });
    
    return <Form.Select disabled={disabled} defaultValue={theDefaultValue(value)} onChange={(e)=>onChange(e.target.value === default_opt_title ? default_opt_value : e.target.value)} required={required}>
                {(keepDefault || !is_selected) && <option value={default_opt_value}>{default_opt_title}</option>}
                {optionsList}
            </Form.Select>
}