import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MButton } from "../../components/Buttons/MButton";
import { navigateto } from "../../Routes";
import { faCheck, faFolder, faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { useEffect, useState } from "react";
import { datetime_to_str } from "../../Helpers/dates";
import DataTable from "react-data-table-component";
import { filter_search_check_item } from "../../Helpers/DataTableOpt";
import { check_is_200, post } from "../../Helpers/xhr";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const Association_List = () => {


    const { userToken } = useSelector((state) => state.auth);
    const history = useHistory();
    
    const [list, setList] = useState([]);
    const [pendingList, setPendingList] = useState([]);

    const getData = () => {
        let params = {};
        
        post('mairie_asc_list', userToken, params)
        .then(xhr => {
            if(check_is_200(xhr) && ('lst' in xhr.data))
            {
                setList(xhr.data.lst.filter(e=>e.valide));                
                setPendingList(xhr.data.lst.filter(e=>!e.valide));                
            }
            else toast.error(<b>Problème de chargement</b>);
        }).catch(function (error){
            
        });
    }

    useEffect(getData, []);

    const decision = (isdelete, assoc, choix=false) => {
        
        const toastId = toast.loading('Chargement');
        
        post(isdelete ? 'mairie_asc_rmv' : 'mairie_asc_val', userToken, {tkn:assoc.tkn, dec:choix})
        .then(xhr => {
            if(check_is_200(xhr) && ('state' in xhr.data))
            {
                if(xhr.data.state)
                {
                    if(isdelete)
                        setList(list.filter(e=>e.tkn!=assoc.tkn));
                    else
                    {
                        setPendingList(pendingList.filter(e=>e.tkn!=assoc.tkn));
                        if(choix) setList([ ...list, {...assoc} ]);
                    }
                    toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>{'Problème de '+(isdelete ? 'suppression' : 'validation')}</b>, { id: toastId, });
            }
            else toast.error(<b>Problème de chargement</b>, { id: toastId, });
        }).catch(function (error){
            toast.error(<b>Problème de chargement</b>, { id: toastId, });
        });
    }
    
    const [filterKey, setFilterKey] = useState('');

    const columns = [
        { name:'Nom', sortable:true, selector:e=>e.nom ?? ''},
        { name:'Date', sortable:true, selector:e=>datetime_to_str(e.date)},
        { name:'Existance', sortable:true, width:'120px', selector:e=>e.existance},
        { name:'Actions', width:'170px', cell:e=><div className="tdIcons">
                                                        <MButton className="small" iconb={<FontAwesomeIcon icon={faInfo}/>} onClick={()=>navigateto({history, to:'association_info', params:e.tkn})}/>
                                                        <MButton className="small" iconb={<FontAwesomeIcon icon={faTimes}/>} onClick={()=>decision(true, e)}/>
                                                    </div>
        },
    ];


    const pendingColumns = [
        { name:'Nom', sortable:true, selector:e=>e.nom ?? ''},
        { name:'Date', sortable:true, selector:e=>datetime_to_str(e.date)},
        { name:'Existance', sortable:true, width:'120px', selector:e=>e.existance},
        { name:'Actions', width:'170px', cell:e=><div className="tdIcons">
                                                        <MButton className="small" iconb={<FontAwesomeIcon icon={faInfo}/>} onClick={()=>navigateto({history, to:'association_info', params:e.tkn})}/>
                                                        <MButton className="small" iconb={<FontAwesomeIcon icon={faCheck}/>} onClick={()=>decision(false, e, true)}/>
                                                        <MButton className="small" iconb={<FontAwesomeIcon icon={faTimes}/>} onClick={()=>decision(false, e, false)}/>
                                                    </div>
        },
    ];


    const show_table = ({title, cols, rows}) => <>
        {rows.length > 0 && <div>
            <div className="pageSubTitle">{title}</div>        
            <DataTable
                columns={cols}
                data={rows}
                pagination
                fixedHeader
                paginationPerPage={40}
                paginationRowsPerPageOptions={[40, 60, 100]}
                noDataComponent="Aucune association !"
                paginationComponentOptions={{
                                                rowsPerPageText: 'Association par page',
                                                rangeSeparatorText: 'sur',
                                                selectAllRowsItem: true,
                                                selectAllRowsItemText: 'Tous',
                                            }}
                />
        </div>}
    </>
    
    return ( <>
        <div className="pageHeader">
        <div className="flex justify-between">
                <div className="flex flex-wrap">
                    <div className="pageTitle">Association</div>
                    <div style={{margin:'5px 20px'}}>
                        <MButton className="small" onClick={()=>navigateto({history, to:'association_docs_cats'})} iconb={<FontAwesomeIcon icon={faFolder}/>} titre="Document" />
                    </div>
                </div>
                <div>
                    {Attribut({ isRow:true, contentSize:9, key:"Filtrer", child:<Input_Text value={filterKey} onChange={setFilterKey} />})}
                </div>
            </div>
        </div>

        {show_table({title:"Association en attente", cols:pendingColumns, rows:pendingList})}
        {show_table({title:"Association", cols:columns, rows:list})}
        
    </> );
}
 
export default Association_List;