import { Form } from "react-bootstrap";

const adapt_value = (val, isInt, isFloat) => {
    let result = val;
    if(isFloat) result = parseFloat(val);
    else if(isInt) result = parseInt(val).toFixed(0);
    else return result;

    if (isNaN(result)) result = '';
    return result;
}

const on_change_action = (e, onChange, isInt, isFloat) => {
    onChange(adapt_value(e.target.value, isInt, isFloat));
}

export function Input_Text({
    value,
    placeHolder,
    onChange,
    onBlur,
    as,
    min,
    max,
    rows,
    type='text',
    required=false,
    isFloat=false,
    isInt=false,
    className='',
    step
})
{
    return <Form.Control
                    type={isInt ? 'number' : type} 
                    className={className}
                    placeHolder={isFloat && !placeHolder?"0.00":placeHolder} 
                    min={min} 
                    max={max} 
                    step={step ?? (isInt ? 1 : (isFloat ? 0.01 : null))}
                    as={as} 
                    rows={rows} 
                    value={value}  
                    pattern={isInt ? [0-9] : (isFloat ? "[0-9]+([\.,][0-9]+)?" : null)}
                    onChange={(e)=>on_change_action(e, onChange, isInt, isFloat)} 
                    required={required}
                />;
}

export function handle_change_input_item_array(value, element, item, setter = () => {}, filter_by = (e) => true) {
    element[item] = value;
    let elements = [...element].filter(item => filter_by(item));
    setter(elements);
}


export function handle_change_input_objet_in_array(elements, element, attribute, value, setter = () => {}) {
    //let ele = elements.filter(e=>e==element);
    //if(!ele) return '';
    //ele[attribute] = value; 
    
    //let Nelements = [...(elements.map(e=>))];
    setter([...elements].map(e=>{
        if(e==element) e[attribute] = value
        return e;
    }));
}