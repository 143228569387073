import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { navigateto } from "../../Routes";
import { MButton } from "../../components/Buttons/MButton";
import Home_Info from "./info";

const Accueil = () => {

    const history = useHistory();
    
    const { isConnected, mairie } = useSelector(
        (state) => state.auth
      );

    const ConnectedView = () => <Home_Info/>

    const NonConnectedView = () => <>
        <div className="pageHeader">
            <div className="flex justify-between">
                <h1 className="pageTitle">Mairies infrastructure</h1>
                <MButton onClick={()=>navigateto({history, to:'connect_login'})} titre="Connexion"/>
            </div>
        </div>
    </>

    return ( <>
        {isConnected ?  <ConnectedView/> : <NonConnectedView/>}
    </> );
}
 
export default Accueil;