import { Form } from "react-bootstrap";


export function Input_Check({
    value=true,
    type="switch",
    label="",
    onChange = ()=>{},
})
{
    return <Form.Check
                type={type}
                label={label}
                checked={value}
                onClick={e=>onChange(e.target.checked)}
            />;
}