export function Map_to_select_options(data)
{
    return Object.entries(data).map( e => Object({value:e[0], title:e[1]}) );
}

export function OuiNonList() { return [
    {title:"Oui", value:"oui"},
    {title:"Non", value:"non"},
]; }


let claim_states = {
    'opn' : {'title':'Ouvert', 'color':'#efd57c'},
    'ect' : {'title':'En cours de traitement', 'color':'#ffbb52'},
    'trt' : {'title':'Traitée', 'color':'#98d8c4'},
    'cls' : {'title':'Fermée', 'color':'#dbdcd9'},
    }

export const get_claim_states = () => claim_states;
export const get_claim_state_name = (type) => type in claim_states ? claim_states[type].title : "";
export const get_claim_state_color = (type) => type in claim_states ? claim_states[type].color : "";
export const options_claim_states = () => Object.entries(claim_states).map( (e) => Object({value:e[0], title:e[1].title}) );

let daysOfWeek = [
    { name:'Lundi', abbr:'lun' },
    { name:'Mardi', abbr:'mar' },
    { name:'Mercredi', abbr:'mer' },
    { name:'Jeudi', abbr:'jeu' },
    { name:'Vendredi', abbr:'ven' },
    { name:'Samedi', abbr:'sam' },
    { name:'Dimanche', abbr:'dim' },
];

export const get_day_of_week_name = (ind) => daysOfWeek[ ( ind + daysOfWeek.length - 1 ) % daysOfWeek.length ].name;
export const get_day_of_week_abbr = (ind) => daysOfWeek[ ( ind + daysOfWeek.length - 1 ) % daysOfWeek.length ].name;

export const get_existance_request_color = (days) => {
    if(isNaN(days)) return '';
    else if(days < 4) return '#a5f59c';
    else if(days < 11) return '#fde480';
    else return '#ff6148';
}

export const get_value_list_objets = (list, keySearch, valueSearch, keyValue) => {
    for(let obj of list )
        if(obj.hasOwnProperty(keySearch) && obj.hasOwnProperty(keyValue) && obj[keySearch == valueSearch]) return obj[keyValue];
    return ''; 
}