import { useState } from "react";
import { Form, Row } from "react-bootstrap";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { MButton } from "../../components/Buttons/MButton";
import toast from "react-hot-toast";
import { check_is_200, post } from "../../Helpers/xhr";
//import store from './app/store';
import { useDispatch, useSelector } from "react-redux";
import { save } from "../../app/features/auth/authSlice";
import { navigateto } from "../../Routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const LoginPage = () => {
    
    const { isConnected, userInfo, userToken } = useSelector((state) => state.auth);
    
    const history = useHistory();

    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const [login, setLogin] = useState('user1@monclub.online');
    const [password, setPassword] = useState('salam');

    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        const toastId = toast.loading('Vérification');
        
        if(form.checkValidity() == true)
        {
            post('auth', null, {eml:login, mdp:password}).then(xhr => {
                if(check_is_200(xhr))
                {
                    if((xhr.data.state ?? false) == true && xhr.data.info)
                    {
                        toast.success(<b>Connecté</b>, { id: toastId, });
                        console.log((xhr.data));
                        dispatch(save(xhr.data));
                    }
                    else toast.error(<b>Incorrect !</b>, { id: toastId, });
                }
                else toast.error(<b>Problème !</b>, { id: toastId, });
                    
            });
        
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }

    return ( <div>
        <div className="pageHeader">
            <h1 className="pageTitle">Mairie</h1>
            <h1 className="pageSubTitle">Connectez-vous</h1>
        </div>
        <div className="content_bloc">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    {Attribut({key:'Nom', isRow:true, child:<Input_Text required={true} value={login} onChange={e=>setLogin(e)} />})}
                </Row>
                <Row>
                    {Attribut({key:'Mot de passe', isRow:true, child:<Input_Text type='password' required={true} value={password} onChange={e=>setPassword(e)} />})}
                </Row>
                <MButton type='submit' titre="Connexion" variant="primary"/>
                <MButton type='reset' titre="Annuler" onClick={()=>navigateto({ history, to:'/' })}/>
            </Form>
        </div>
    </div> );
}
 
export default LoginPage;