import { Col, Form, Row } from "react-bootstrap";

export function Attribut({
        key, 
        with_border,
        value,
        isRow=false,
        isForm=true,
        labelSize,
        contentSize=8,
        contentPadding,
        contentClassName="",
        size,
        offset,
        child=<></>
    }){

    labelSize = 12 - contentSize
    if(!isRow){
        labelSize = 12;
        contentSize = 12;
    }
    if(key == null) contentSize = 12;

    if(!isForm) contentPadding = "7px";

    return <Col as={isRow ? Row : Col} sm={size}>
                {key && <Form.Label column sm={labelSize}>{key}</Form.Label>}
                <Col column sm={contentSize} md={{offset:offset}} className={(with_border ? 'value_zone' : '')+" "+contentClassName} style={{paddingTop:contentPadding}}>
                    {value && <div style={{paddingTop:'7px'}}>{value}</div>}
                    {child}
                </Col>
            </Col>;
}