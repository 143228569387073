import { useSelector } from "react-redux";
import { check_is_200, post } from "../../Helpers/xhr";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { faClock, faClockRotateLeft, faCog, faPlus, faTimeline, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MButton } from "../../components/Buttons/MButton";
import { filter_search_check_item } from "../../Helpers/DataTableOpt";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { MyModal } from "../../components/modals/myModal";
import { Row } from "react-bootstrap";
import { TimeSlotForm, TimeSlotView } from "../../components/form/TimeSlot";
import toast from "react-hot-toast";

const Mairie_Services = () => {
    
    const { userToken } = useSelector(state=>state.auth);
    const history = useHistory();

    const goBack = ()=>history.goBack();
    const [services, setServices] = useState([]);

    const getData = () => {
        post('mairie_service_list', userToken )
        .then(xhr=>{
            console.log(xhr.data);

            if( check_is_200(xhr) && 'lst' in xhr.data)
            {
                setServices(xhr.data.lst);
            }
        });
    }

    useEffect(getData, []);
    
    const showTimes = (service) => {
        setShowHoraire(true);
        setSelected_Service(service);
    }

    const updateService = (service) => {
        setModal_title("Modification du service"); setModal_validated(false); setSelected_Service_Init(service); setSelected_Service(service); setModal_show(true);
    }

    const deleteService = (service) => {

    }

    const [modal_title, setModal_title] = useState('');
    const [modal_show, setModal_show] = useState(false);
    const [modal_validated, setModal_validated] = useState(false);
    const [selected_Service_Init, setSelected_Service_Init] = useState({});
    const [selected_Service, setSelected_Service] = useState({});
    const [showHoraire, setShowHoraire] = useState(false);
    
    const hide_modal = () => setModal_show(false);
    const getForm = () => {
        return <>
                <Row>
                    {Attribut({key:'Nom', child:<Input_Text required={true} value={selected_Service.nom ?? ''} onChange={v=>setSelected_Service({...selected_Service, nom:v})} />})}
                </Row>
                <Row>
                    {Attribut({key:'Tel', child:<Input_Text required={true} value={selected_Service.tel ?? ''} onChange={v=>setSelected_Service({...selected_Service, tel:v})} />})}
                </Row>
                <Row>
                    {Attribut({key:'Email', child:<Input_Text required={true} value={selected_Service.email ?? ''} onChange={v=>setSelected_Service({...selected_Service, email:v})} />})}
                </Row>
                <Row>
                    {Attribut({key:'Horaires', child:<TimeSlotForm horaires={selected_Service.horaires ?? []} setHoraires={hrs=>setSelected_Service({...selected_Service, horaires:hrs})}/>})}
                </Row>
            </>;
    }
    
    const init_Form = () => {
        setSelected_Service({});
        setModal_validated(false);
        setSelected_Service_Init(null);
        hide_modal();
    };

    const handleSubmit = (event) => {
        let form = event.currentTarget;
        event.preventDefault();
        
        setModal_validated(true);

        const toastId = toast.loading('Enregistrement');
        
        if( form.checkValidity() == true )
        {
            post('mairie_service_save', userToken, selected_Service)
            .then(xhr=>{
                if(check_is_200(xhr) && 'state' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            setModal_show(false);
                            if(selected_Service_Init == null)
                                setServices([...services].push(selected_Service));
                            else setServices([...services].map(e=>e==selected_Service_Init ? selected_Service : e));
                        }
                        else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else toast.error(<b>Problème d'envoi</b>, { id: toastId, });
                        
                });
        }else toast.error(<b>Veuillez remplir le formulaire !</b>, { id: toastId, });
    }
    

    const showNewForm = () => { setModal_title("Nouveau service"); setModal_validated(false); setSelected_Service_Init(null); setSelected_Service({}); setModal_show(true); }

    const [filterKey, setFilterKey] = useState('');

    const columns = [
        { name:'Nom', sortable:true, selector:r=>r.nom??''},
        { name:'Télephone', sortable:true, selector:r=>r.tel??''},
        { name:'Email', sortable:true, selector:r=>r.email??''},
        { name:'Actions', width:'200px', cell:e=><div className="tdIcons">
                                                    <MButton className='small' iconb={<FontAwesomeIcon icon={faClock} />}  onClick={()=>showTimes(e)}  />
                                                    <MButton className='small' iconb={<FontAwesomeIcon icon={faCog}/>} onClick={()=>updateService(e)} />
                                                    <MButton className="small" iconb={<FontAwesomeIcon icon={faTimes}/>} onClick={()=>deleteService(e)}/>
                                                  </div>
        },
    ];

    
    return ( <>
        <div className="pageHeader"><div className="flex justify-between">
          <div className="flex flex-wrap">
            <div className="pageTitle">Services</div>
            <div style={{margin:'5px 20px'}}>
              <MButton 
                className="small"
                  titre="Ajouter" 
                  iconb={<FontAwesomeIcon icon={faPlus} />}
                  onClick={()=>showNewForm()}
                />
            </div>
          </div>
          <div>
            {Attribut({ isRow:true, contentSize:9, key:"Filtrer", child:<Input_Text value={filterKey} onChange={setFilterKey} />})}
          </div>
        </div>
        </div>
        <DataTable
            columns={columns}
            data={services.filter(item => {
                let filter_key = filterKey.toLocaleLowerCase();
                return filter_search_check_item(filter_key, item, ['nom', 'tel', 'email']);
            })}
            
            pagination
            fixedHeader
            paginationPerPage={40}
            paginationRowsPerPageOptions={[40, 60, 100]}
            noDataComponent="Aucune service !"
            paginationComponentOptions={{
                                            rowsPerPageText: 'service par page',
                                            rangeSeparatorText: 'sur',
                                            selectAllRowsItem: true,
                                            selectAllRowsItemText: 'Tous',
                                        }}
            
        />

        <MyModal
            title={modal_title}
            show={modal_show}
            handleClose={hide_modal}
            dynamicContent={getForm}
            isForm={true}
            handleSubmit={handleSubmit}
            validated={modal_validated}
            buttons={[
                {title:'Enregistrer', type:'submit', variant:'primary'},
                {title:'Annuler', type:'button', click:()=>init_Form()},
            ]}
        />

        
        <MyModal
            show={showHoraire && selected_Service != null}
            content={<TimeSlotView horaires={selected_Service ? selected_Service.horaires : []}/>}
            title={"Service "+(selected_Service ? selected_Service.nom : '')}
            handleClose={()=>setSelected_Service(null)}
            buttons={[{'title':"Fermer", 'click':()=>setShowHoraire(false)}]}
        />

    </> );
}
 
export default Mairie_Services;