import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MButton } from "../../components/Buttons/MButton";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { MyModal } from "../../components/modals/myModal";
import { Attribut } from "../../components/form/attribut";
import { Input_Select } from "../../components/form/inputSelect";
import { Input_Text } from "../../components/form/inputText";
import { get_claim_state_color, get_claim_state_name, options_claim_states } from "../../Helpers/Variants";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { check_is_200, post } from "../../Helpers/xhr";

const Reclamation_Etat = ({ claim, userToken, onChange, spaceBetween }) => {

    const [state, setState] = useState('');
    const [commentaire, setCommentaire] = useState('');
    const [modalShow, setModalShow] = useState(false);

    const [validated, setValidated] = useState(false);

    const open = () => {
        setState(claim.etat);
        setCommentaire("");
        setValidated(false);
        setModalShow(true);
    }
    const closeShowModal = () => setModalShow(!modalShow);
    const modalContent = () => {
        return <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {Attribut({ key:"Etat", child:<Input_Select required value={state} options={options_claim_states()} onChange={setState} /> })}
            {Attribut({ key:"Commentaire", child:<Input_Text as="textarea" value={commentaire} onChange={setCommentaire}/> })}
            <MButton type="submit" titre="Enregistrer" variant="primary" />
            <MButton type="button" titre="Annuler" onClick={closeShowModal} />
        </Form>
    }


    const handleSubmit = (event) => {
            const form = event.currentTarget;
            event.preventDefault();
            setValidated(true);
            
            if(form.checkValidity() == true)
            {
                const toastId = toast.loading('Enregistrement');
                post('claim_sstate', userToken, {tkn:claim.tkn, stt:state, msg:commentaire, byp:'a'}).then(xhr => {
                    if(check_is_200(xhr) && 'state' in xhr.data)
                    {
                        if(xhr.data.state)
                        {
                            toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                            if(onChange) onChange(state);
                            setModalShow(false);
                        }
                        else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                    }
                    else toast.error(<b>Problème d'envoi</b>, { id: toastId, });      
                });
            
            }
    }

    return ( <>
        <div className={spaceBetween ? "flex justify-between" : ""} style={{backgroundColor:get_claim_state_color(claim.etat)}}>
            <div className={"whitespace-nowrap "+(spaceBetween ? "p-1" : "")}>
                {get_claim_state_name(claim.etat)}
            </div>
            <MButton className="small" iconb={<FontAwesomeIcon icon={faCog} />} onClick={open} />  
        </div>

        <MyModal
            title="Etat de la réclamation"
            handleClose={()=>closeShowModal()}
            show={modalShow}
            dynamicContent={modalContent}
        />
    </> );

    
}
 
export default Reclamation_Etat;