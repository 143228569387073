import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { check_is_200, post } from "../../Helpers/xhr";
import toast from "react-hot-toast";
import { get_day_of_week_name } from "../../Helpers/Variants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faTimes } from "@fortawesome/free-solid-svg-icons";
import { MButton } from "../../components/Buttons/MButton";
import { Attribut } from "../../components/form/attribut";
import { Input_Text } from "../../components/form/inputText";
import { Form } from "react-bootstrap";
import { TimeSlotForm } from "../../components/form/TimeSlot";

const Infrastructure_Hours = ({ annuler }) => {
    
    const { userToken } = useSelector(state=>state.auth);
    
    const history = useHistory();
    const { infra_key } = useParams();

    const [validated, setValidated] = useState(false);

    const [infos, setInfos] = useState({});
    const [horaires, setHoraires] = useState([]);

    const getData = () => {
        const toastId = toast.loading('Chargement');
        
        post('infra_hours', userToken, {infra:infra_key,})
        .then(xhr => {
            if( check_is_200(xhr) && 'state' in xhr.data )
            {
                if(xhr.data.state)
                {
                    setHoraires(xhr.data.hours || []);
                    setInfos(xhr.data.infos || {});
                    toast.success(<b>Terminé</b>, { id: toastId, });
                } else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
            } else toast.error(<b>Problème de chargement !</b>, { id: toastId, });
        });
    }

    useEffect(getData, []);
    


    
    const goBack = () => history.goBack();


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        
        if(form.checkValidity() == true)
        {
            const toastId = toast.loading('Enregistrement');

            post('infra_hours_save', userToken, {infra:infra_key, hours:horaires})
            .then(xhr => {
                if(check_is_200(xhr) && 'state' in xhr.data)
                {
                    if(xhr.data.state)
                    {
                        toast.success(<b>{xhr.data.msg}</b>, { id: toastId, });
                        resetAction();
                    }
                    else toast.error(<b>{xhr.data.msg}</b>, { id: toastId, });
                }
                else toast.error(<b>Problème d'envoi</b>, { id: toastId, }); 
            }).catch(()=>toast.error(<b>Problème d'envoi</b>, { id: toastId, }));
        }
        
    }

    const resetAction = annuler != null ? annuler : goBack;

    return ( <>
    
        <div className="pageHeader">
            <h1 className="pageTitle">Horaires de l'infrastructure</h1>
        </div>
        <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <TimeSlotForm horaires={horaires} setHoraires={setHoraires}/>
                <div>
                    <MButton type="submit" titre="Enregistrer" variant="primary"/>
                    <MButton type="button" titre="Annuler" onClick={resetAction}/>
                </div>
            </Form>
        </div>
    </> );
}
 
export default Infrastructure_Hours;